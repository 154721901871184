import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Avatar from "./Avatar";
import clsx from "clsx";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export const SearchType = {
  At_Groups: {
    short: "@Groups",
    long: "Search for Groups",
    placeholder: "Group Name",
  },
  At_Users: {
    short: "@Users",
    long: "Addressed to Users",
    placeholder: "First or Last Name",
  },
  By_Users: {
    short: "@Users",
    long: "Search for Users",
    placeholder: "First or Last Name",
  },
  Keywords: {
    short: "Keywords",
    long: "Search Posts by Keywords",
    placeholder: "Search",
  },
};
export const SortType = {
  Newest_Posts: "newest_posts",
  Most_Recent_Activity: "most_recent_activity",
};

function Navbar({
  user,
  token = null,
  previousUrl = null,
  onBack = null,
  title = "",
  addKeywords = null,
  activeSearch = false,
  setActiveSearch = null,
  addPostedToGroup = null,
  addPostedToUser = null,
  addPostedByUser = null,
  setSearchType = null,
  searchType = null,
  alertCount = 0,
  onSortChange = () => {},
}) {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [keywords, setKeywords] = useState("");
  const navigate = useNavigate();
  const [suggestedUsers, setSuggestedUsers] = useState();
  const [suggestedGroups, setSuggestedGroups] = useState();
  const input = useRef();
  const [currentSortType, setCurrentSortType] = useState(null);

  let interval = {};
  useEffect(() => {
    clearInterval(interval);
    if (keywords.length >= 2 && searchType != SearchType.Keywords) {
      const keywordsBeforeInterval = keywords;
      interval = setInterval(() => {
        clearInterval(interval);
        if (keywords === keywordsBeforeInterval) {
          performSuggestionsSearch();
        }
      }, 800);
    } else {
      // console.log(`not long enough: ${keywords}`);
    }
    return () => clearInterval(interval);
  }, [keywords]);

  useEffect(() => {
    const localValue = localStorage.getItem("sort_posts");
    if (localValue && localValue != "") {
      setCurrentSortType(localValue);
    } else {
      setCurrentSortType(SortType.Newest_Posts);
    }
  }, []);

  const handleKeywordsChange = (e) => {
    setSuggestedUsers(null);
    setSuggestedGroups(null);
    setKeywords(e.target.value);
  };

  const performSuggestionsSearch = () => {
    const data = { keywords: keywords, bypass_private: true };
    if (searchType == SearchType.At_Groups) {
      data.groups_only = true;
    } else if (
      searchType == SearchType.At_Users ||
      searchType == SearchType.By_Users
    ) {
      data.users_only = true;
    }
    axios
      .get(`/api/v1/suggestions/`, {
        params: data,
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        console.log(response.data);
        setSuggestedUsers(response.data.users || null);
        setSuggestedGroups(response.data.groups || null);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.error);
        console.log(error);
      });
  };

  const onTypeChange = (type) => {
    setSearchType(type);
    setKeywords("");
    setSuggestedGroups(null);
    setSuggestedUsers(null);
    input.current.focus();
  };

  const onSortTypeChange = (type) => {
    localStorage.setItem("sort_posts", type);
    if (onSortChange) {
      onSortChange(type);
    }
    setCurrentSortType(type);
  };

  const searchInput = () => (
    <div
      key="input"
      className={clsx({
        "w-100 d-flex gap-2": true,
        "justify-content-between": !activeSearch,
        "justify-content-end": activeSearch,
      })}
    >
      {!activeSearch && (
        <div className="position-relative d-flex align-items-center">
          <button
            className="btn btn-outline-primary"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            onClick={() => {
              setShowSideMenu(true);
            }}
          >
            &#9776;
          </button>
          <Link
            to={"/alerts"}
            className="btn d-flex align-items-center position-relative"
            style={{ borderRadius: "0" }}
          >
            <i className="fa fa-bell"></i>
            {alertCount > 0 && (
              <div
                className="position-absolute rounded-circle d-flex justify-content-center align-items-center text-white"
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#d51111",
                  fontSize: "11px",
                  pointerEvents: "none",
                  top: "1px",
                  right: "-2px",
                }}
              >
                {alertCount}
              </div>
            )}
          </Link>
          {currentSortType && (
            <DropdownButton
              id="sort-dropdown-button"
              variant=""
              title={
                <span>
                  <i className="fa fa-arrow-down-wide-short"></i>
                </span>
              }
            >
              <Dropdown.Item
                href="#"
                onClick={() => onSortTypeChange(SortType.Newest_Posts)}
                disabled={currentSortType == SortType.Newest_Posts}
              >
                Newest Posts
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => onSortTypeChange(SortType.Most_Recent_Activity)}
                disabled={currentSortType == SortType.Most_Recent_Activity}
              >
                Most Recent Activity
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
      )}
      <Form
        className="flex-grow-1"
        style={{ maxWidth: activeSearch ? "450px" : "350px" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (searchType == SearchType.Keywords) {
            addKeywords(keywords);
            setKeywords("");
          }
        }}
      >
        <InputGroup className="position-relative">
          {activeSearch && (
            <DropdownButton
              variant="outline-secondary bg-white position-absolute"
              title={searchType.short}
              id="nav-search-dropdown-btn"
            >
              <Dropdown.Item
                href="#"
                onClick={() => onTypeChange(SearchType.At_Groups)}
              >
                {SearchType.At_Groups.long}
              </Dropdown.Item>
              {/* <Dropdown.Item
                href="#"
                onClick={() => onTypeChange(SearchType.At_Users)}
              >
                {SearchType.At_Users.long}
              </Dropdown.Item> */}
              <Dropdown.Item
                href="#"
                onClick={() => onTypeChange(SearchType.By_Users)}
              >
                {SearchType.By_Users.long}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                href="#"
                onClick={() => onTypeChange(SearchType.Keywords)}
              >
                {SearchType.Keywords.long}
              </Dropdown.Item>
            </DropdownButton>
          )}
          <Form.Control
            ref={input}
            type="search"
            className="text-medium-dark"
            style={activeSearch ? { paddingLeft: "120px" } : {}}
            placeholder={searchType.placeholder}
            aria-label="Text input with dropdown button"
            value={keywords}
            onChange={handleKeywordsChange}
            onFocus={() => setActiveSearch(true)}
          />
          {activeSearch && (
            <button
              className="btn btn-outline-secondary bg-white position-absolute"
              style={{ top: "0", right: "0" }}
              type="button"
              id="nav-search-close-btn"
              onClick={() => {
                setActiveSearch(false);
                setKeywords("");
                setSuggestedGroups(null);
                setSuggestedUsers(null);
                setSearchType(SearchType.Keywords);
              }}
            >
              <i className="fa fa-close"></i>
            </button>
          )}
        </InputGroup>
      </Form>
    </div>
  );
  const goBackView = () => (
    <div className="w-100">
      <h5 className="text-center text-primary">{title}</h5>
      <button
        className="btn btn-outline-primary ml-3 position-absolute"
        style={{ top: "5px", left: "10px" }}
        type="button"
        onClick={() => {
          if (previousUrl) {
            navigate(previousUrl);
          } else if (onBack) {
            onBack();
          }
        }}
      >
        <i className="fa fa-chevron-left"></i>
      </button>
    </div>
  );
  const addUser = (user) => {
    if (searchType == SearchType.At_Users) {
      addPostedToUser(user);
    } else if (searchType == SearchType.By_Users) {
      addPostedByUser(user);
    }
  };

  const addGroup = (group) => {
    addPostedToGroup(group);
  };
  return (
    <div className="position-relative">
      <nav className="navbar navbar-dark bg-secondary px-2">
        {(previousUrl || onBack) && goBackView()}
        {!previousUrl && !onBack && searchInput()}
      </nav>
      {(suggestedUsers || suggestedGroups) && (
        <>
          <div
            className="bg-light w-100 vh-100 position-fixed"
            style={{ opacity: "0.4", zIndex: "1" }}
            onClick={() => {
              console.log("click");
              setSuggestedGroups(null);
              setSuggestedUsers(null);
              setKeywords("");
            }}
          />

          <div
            className="position-absolute"
            style={{
              top: "60px",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "1",
            }}
          >
            <div
              className="list-group bg-white shadow position-absolute"
              style={{
                maxHeight: "190px",
                width: "360px",
                top: "0",
                right: "10px",
                zIndex: "2",
              }}
            >
              {suggestedGroups &&
                suggestedGroups.map((group) => (
                  <button
                    key={`group-${group.id}`}
                    type="button"
                    className="list-group-item list-group-item-action d-flex gap-2 align-items-center"
                    onClick={() => {
                      addGroup(group);
                      setSuggestedGroups(null);
                      setSuggestedUsers(null);
                      setKeywords("");
                    }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <i
                        className="fa fa-group"
                        style={{ fontSize: "22px" }}
                      ></i>
                    </div>
                    {` ${group.name}`}
                  </button>
                ))}
              {suggestedUsers &&
                suggestedUsers.map((user) => (
                  <button
                    key={`user-${user.id}`}
                    type="button"
                    className="list-group-item list-group-item-action d-flex gap-2 align-items-center"
                    onClick={() => {
                      addUser(user);
                      setSuggestedGroups(null);
                      setSuggestedUsers(null);
                      setKeywords("");
                    }}
                  >
                    <Avatar user={user} />
                    <div>
                      <span className="d-block">{`${user.first_name} ${user.last_name}`}</span>
                      {user.screen_name !=
                        `${user.first_name} ${user.last_name}` && (
                        <span className="text-medium small">
                          {user.screen_name}
                        </span>
                      )}
                    </div>
                  </button>
                ))}
            </div>
          </div>
        </>
      )}
      {!previousUrl && (
        <Offcanvas
          show={showSideMenu}
          onHide={() => setShowSideMenu(false)}
          className="bg-light"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => setShowSideMenu(false)}
            ></button>
          </div>
          {user && (
            <Offcanvas.Body className="p-0 my-list-menu">
              <Link
                to={"/users/" + user.id}
                className="btn btn-lg bg-white d-flex align-items-center"
                style={{ borderRadius: "0" }}
              >
                <Avatar user={user} />
                <div className="px-2 d-flex flex-column">
                  <h6 className="m-0">
                    {user.first_name + " " + user.last_name}
                  </h6>
                  {user.screen_name !=
                    user.first_name + " " + user.last_name && (
                    <span
                      className="small m-0 text-start text-medium"
                      style={{ fontSize: "14px" }}
                    >
                      {user.screen_name}
                    </span>
                  )}
                </div>
              </Link>
              <div className="d-grid gap-2 mt-3">
                <Link
                  to={"/posts"}
                  className="btn bg-white d-flex align-items-center py-3"
                  style={{ borderRadius: "0" }}
                >
                  <i className="fa fa-plus"></i>
                  <span className="mx-2">Create New Post</span>
                </Link>

                <Link
                  to={"/groups"}
                  className="btn bg-white d-flex align-items-center py-3"
                  style={{ borderRadius: "0" }}
                >
                  <i className="fa fa-group"></i>
                  <span className="mx-2 text-start flex-grow-1">
                    Manage Groups
                  </span>
                </Link>

                <Link
                  to={"/mutes"}
                  className="btn bg-white d-flex align-items-center py-3"
                  style={{ borderRadius: "0" }}
                >
                  <i className="fa fa-bell-slash"></i>
                  <span className="mx-2">Manage Muted Users</span>
                </Link>

                <Link
                  to={"/settings"}
                  className="btn bg-white d-flex align-items-center py-3"
                  style={{ borderRadius: "0" }}
                >
                  <i className="fa fa-cog"></i>
                  <span className="mx-2">Settings</span>
                </Link>
              </div>
            </Offcanvas.Body>
          )}
        </Offcanvas>
      )}
    </div>
  );
}
export default Navbar;
