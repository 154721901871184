import React from "react";
import Avatar from "./Avatar";

function GroupStats({ group, groupData }) {
  return (
    <>
      <div
        className="d-flex justify-content-center bg-medium w-100 rounded-2"
        style={{ minHeight: "80px" }}
      >
        {group.image ? (
          <img
            src={group.image.url_large}
            style={{ width: "100%", height: "180px", objectFit: "cover" }}
            className="rounded-2"
          />
        ) : (
          <div
            className="rounded-circle bg-medium d-flex justify-content-center align-items-center"
            style={{ width: "180px", height: "180px" }}
          >
            <i className="fa fa-group" style={{ fontSize: "59px" }}></i>
          </div>
        )}
      </div>

      <h5 className="mt-3 text-medium-dark">{group.name}</h5>
      <p className="mb-3 px-2 text-medium-dark">{group.description}</p>
      <div className="mt-2 text-center">
        <p className="text-medium-dark mb-1">
          Total Posts:{" "}
          <span className="text-secondary fw-bold">
            {groupData.total_posts}
          </span>
        </p>

        <p className="text-medium-dark mb-1">
          Total Members:{" "}
          <span className="text-secondary fw-bold">
            {groupData.users.length}
          </span>
        </p>
      </div>
      {group.by_invitation_only ? (
        <p className="mt-2 text-medium-dark">
          <i className="fa fa-lock text-medium-dark"></i>{" "}
          <span style={{ marginLeft: "6px" }}>
            Membership is by invitation only
          </span>
        </p>
      ) : (
        <p className="mt-2 text-medium-dark">
          <span>Membership is open</span>
        </p>
      )}
    </>
  );
}
export default GroupStats;
