import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./Home";
import User from "./User";
import Mutes from "./Mutes";
import Settings from "./Settings";
import Groups from "./Groups";
import Group from "./Group";
import AddUser from "./AddUser";
import PostForm from "./PostForm";
import Login from "./Login";
import Logout from "./Logout";
import { Link } from "react-router-dom";
import Chalupa404Image from "images/chalupa_404.png";
import GroupUser from "./GroupUser";
import Alerts from "./Alerts";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/home" element={<Home />} />
          <Route path="users/:id" element={<User />} />
          <Route path="/posts" element={<PostForm />} />
          <Route path="/posts/:id" element={<PostForm />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/groups/:id" element={<Group />} />
          <Route path="/groups/:groupId/users" element={<AddUser />} />
          <Route path="/groups/:groupId/users/:id" element={<GroupUser />} />
          <Route path="/mutes" element={<Mutes />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/alerts" element={<Alerts />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );

  function NoMatch() {
    return (
      <div className="d-flex justify-content-center">
        <div className="text-center mt-5">
          <h3 className="text-secondary">404 ERROR PAGE</h3>
          <img
            src={Chalupa404Image}
            className="card-img-top"
            alt="Chalupa Image"
            style={{ height: "190px" }}
          />
          <p>Uh oh! Nothing here...</p>
          <Link to="/" className="btn btn-lg btn-primary">
            Back to Home
          </Link>
        </div>
      </div>
    );
  }
}
export default App;
