import React, { useState } from "react";
import axios from "axios";
import Form from "../Form";

function Password(props) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordSuccessMessage, setPasswordSuccessMessage] = useState("");

  const handleUpdatePassword = (event) => {
    event.preventDefault();
    setPasswordErrorMessage("");
    setPasswordSuccessMessage("");
    // let data = {user: {email: props.user.email, password: password, password_confirm: passwordConfirm}}
    let data = {
      user_id: props.user.id,
      password: password,
      password_confirm: passwordConfirm,
    };
    axios
      .put(`/api/v1/users/${props.user.id}/password`, data, {
        withCredentials: true,
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((response) => {
        setPasswordSuccessMessage(response.data.message);
        setPassword("");
        setPasswordConfirm("");
      })
      .catch((error) => {
        setPasswordErrorMessage(error.response.data.error);
      });
  };

  return (
    <>
      <Form
        handleSubmit={handleUpdatePassword}
        submitLabel="Change Password"
        successMessage={passwordSuccessMessage}
        errorMessage={passwordErrorMessage}
      >
        <div className="mb-3">
          <label className="form-label text-dark">New Password</label>
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            className="form-control"
            id="pass"
            autoComplete="on"
          />
        </div>
        <div className="mb-3">
          <label className="form-label text-dark">New Password Again</label>
          <input
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
            }}
            type="password"
            className="form-control"
            id="passConfirm"
          />
        </div>
      </Form>
    </>
  );
}
export default Password;
