import React, { useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("token");
    axios
      .post(`/logout`)
      .then((response) => {
        navigate("/login");
      })
      .catch((error) => {
        console.log("[logout] error");
      });
  }, []);

  return (
    <>
      <div align="center" className="mt-5">
        <span>You are being logged out.</span>
      </div>
      <Link to="/login" className="btn btn-primary mt-5">
        Login
      </Link>
    </>
  );
}
export default Logout;
