import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Alert from "./Alert";
import { useParams, useNavigate } from "react-router-dom";
import useAuthorization from "../hooks/useAuthorization";
import axios from "axios";
import TagManager from "./post/TagManager";
import { validateEmail } from "../utils";

function AddUser() {
  const { groupId } = useParams();
  const my_user = useAuthorization();
  const [group, setGroup] = useState();
  const [groupData, setGroupData] = useState();
  const [token, setToken] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [errorMessageExistingUser, setErrorMessageExistingUser] = useState("");
  const [errorMessageNewUser, setErrorMessageNewUser] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken);
    if (groupId) {
      console.log("fetching group");

      axios
        .get(`/api/v1/groups/${groupId}`, {
          headers: { Authorization: `Bearer ${localToken}` },
        })
        .then((response) => {
          console.log(response);
          setGroup(response.data.group);
          setGroupData(response.data.group_data);
          if (!response.data.group_data.is_admin) {
            // This person does not have autority to be viewing this page
            navigate(`/home`);
          }
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [groupId]);

  const inviteExistingUser = () => {
    const userId = users[0].id;
    axios
      .post(
        `/api/v1/groups/${group.id}/users/${userId}/invite_to_group`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.error) {
          setErrorMessageExistingUser(response.data.error);
        } else {
          navigate(`/groups/${group.id}`);
        }
      })
      .catch((error) => {
        const apiError = error.response?.data?.error;
        const message = apiError ? apiError : "Could not invite user";
        setErrorMessageExistingUser(message);
      });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // If a valid email address, enable send button
    const isValid = validateEmail(e.target.value);
    setIsValidEmail(isValid);
  };

  const inviteNewUser = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      from_user_id: my_user.id,
    };
    axios
      .post(`/api/v1/groups/${group.id}/invite_new_user_to_group`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        if (response?.data?.error) {
          setErrorMessageNewUser(response.data.error);
        } else {
          navigate(`/groups/${group.id}`);
        }
      })
      .catch((error) => {
        const apiError = error.response?.data?.error;
        const message = apiError ? apiError : "Could not invite user";
        setErrorMessageNewUser(message);
      });
  };

  return (
    <div className="bg-light">
      {group && groupData && (
        <>
          <Navbar previousUrl={`/groups/${group.id}`} title={"Invite User"} />

          <div
            className="row d-flex justify-content-center vh-100 bg-light px-2"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <div className="col-12 col-md-10 col-lg-7 mt-2 d-flex flex-column align-items-center">
              <div className="d-flex gap-2 align-items-center mb-4 text-dark">
                <div
                  className="rounded-circle bg-medium d-flex justify-content-center align-items-center"
                  style={{ width: "40px", height: "40px" }}
                >
                  <i className="fa fa-group" style={{ fontSize: "22px" }}></i>
                </div>
                {` ${group.name}`}
              </div>
              <div className="w-100 bg-medium-light rounded-2 p-3">
                <TagManager
                  title="Invite Existing User"
                  token={token}
                  users={users}
                  setUsers={setUsers}
                  disabled={users.length > 0}
                  bgStyle={{ minHeight: "38px", borderRadius: "6px" }}
                />
                <button
                  className="btn btn-primary"
                  style={{ minWidth: "220px" }}
                  disabled={users.length == 0}
                  onClick={() => inviteExistingUser()}
                >
                  Send
                </button>
              </div>
              {errorMessageExistingUser && (
                <Alert
                  message={errorMessageExistingUser}
                  onClose={() => setErrorMessageExistingUser(null)}
                  type="danger"
                />
              )}
              <p className="w-100 form-label text-medium-dark my-5">OR</p>
              {errorMessageNewUser && (
                <Alert
                  message={errorMessageNewUser}
                  onClose={() => setErrorMessageNewUser(null)}
                  type="danger"
                />
              )}
              <div className="w-100 bg-medium-light rounded-2 p-3">
                <label className="form-label text-medium-dark">
                  Invite New User
                </label>
                <p className="text-medium-dark">
                  <small>
                    An email will be sent and they will have to click the
                    activate button in the email.
                  </small>
                </p>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="First Name"
                  aria-label="first"
                  aria-describedby="basic-addon1"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                ></input>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Last Name"
                  aria-label="last"
                  aria-describedby="basic-addon1"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                ></input>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                  value={email}
                  onChange={handleEmailChange}
                ></input>
                <button
                  className="btn btn-primary mt-3"
                  style={{ minWidth: "220px" }}
                  disabled={
                    !isValidEmail ||
                    firstName.length == 0 ||
                    lastName.length == 0
                  }
                  onClick={() => inviteNewUser()}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default AddUser;
