import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import axios from "axios";
import UserStats from "./UserStats";

function GroupUser() {
  const { id, groupId } = useParams();
  const my_user = useAuthorization();
  const [user, setUser] = useState();
  const [group, setGroup] = useState();
  const [userData, setUserData] = useState();
  const [groupData, setGroupData] = useState();
  const [token, setToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const backUrl = searchParams.get("back");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("constructor");
    const localToken = localStorage.getItem("token");
    setToken(localToken);
    if (id && my_user) {
      if (id == my_user.id) {
        setUser(my_user);
      } else {
        axios
          .get(`/api/v1/users/${id}`, {
            headers: { Authorization: `Bearer ${localToken}` },
          })
          .then((response) => {
            console.log(response);
            setUser(response.data.user);
          })
          .catch((error) => {
            console.log("error");
          });
      }
      // Either way, get the user_data
      const data = { posted_by_users: JSON.stringify([id]) };
      axios
        .get(`/api/v1/posts`, {
          params: data,
          headers: { Authorization: `Bearer ${localToken}` },
        })
        .then((response) => {
          console.log(response);
          setUserData(response.data.user_data);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [id, my_user]);

  useEffect(() => {
    if (groupId) {
      // App user came from a group
      const localToken = localStorage.getItem("token");
      console.log("is a group user");
      axios
        .get(`/api/v1/groups/${groupId}`, {
          headers: { Authorization: `Bearer ${localToken}` },
        })
        .then((response) => {
          console.log(response);
          setGroup(response.data.group);
          setGroupData(response.data.group_data);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [groupId]);

  const removeUserFromGroup = () => {
    axios
      .delete(`/api/v1/groups/${group.id}/users/${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        navigate(`/groups/${group.id}`);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const makeUserAdmin = () => {
    const data = { admin: true };
    axios
      .put(`/api/v1/groups/${group.id}/users/${user.id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        navigate(`/groups/${group.id}`);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  return (
    <>
      {user && group && userData && groupData && (
        <>
          <Navbar
            previousUrl={backUrl ? backUrl : "/home"}
            title={user.id == my_user.id ? "Me" : "User"}
          />
          <div className="row d-flex justify-content-center px-2 vh-100 bg-light">
            <div className="col-12 col-md-10 col-lg-7 py-2">
              <div className="d-flex flex-column align-items-center mb-2">
                <div
                  className="rounded-circle bg-medium d-flex justify-content-center align-items-center"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i className="fa fa-group" style={{ fontSize: "19px" }}></i>
                </div>
                <span className="text-dark mt-1 mb-1">{group.name}</span>
              </div>

              <div className="d-flex flex-column align-items-center">
                <UserStats user={user} userData={userData} />
                {groupData.is_admin && (
                  <div className="d-flex flex-column align-items-center gap-2 mt-3 bg-medium-light rounded-2 p-3 w-100">
                    <p>
                      <small>
                        <i
                          className="fa fa-crown text-medium-dark"
                          style={{ marginRight: "10px" }}
                        ></i>
                        Admin Panel
                      </small>
                    </p>
                    <button
                      className="btn btn-primary"
                      style={{ minWidth: "220px" }}
                      onClick={() => removeUserFromGroup()}
                    >
                      Remove User From Group
                    </button>

                    <button
                      className="btn btn-primary"
                      style={{ minWidth: "220px" }}
                      onClick={() => makeUserAdmin()}
                    >
                      Promote to an Admin
                    </button>
                  </div>
                )}
                {!groupData.is_admin && user.id == my_user.id && (
                  <div className="d-flex flex-column align-items-center gap-2 mt-3 bg-medium-light rounded-2 p-3 w-100">
                    <button
                      className="btn btn-primary"
                      style={{ minWidth: "220px" }}
                      onClick={() => removeUserFromGroup()}
                    >
                      Leave Group
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default GroupUser;
