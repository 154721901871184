import React from "react";
import { shouldUseLightText } from "../utils";

function Avatar({ user, large = false }) {
  const size = large ? "200px" : "40px"; // width and height
  const largeFontStyle = { fontSize: "30px" };
  return (
    <div
      className="rounded-circle d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: user.color,
        width: size,
        height: size,
        color: shouldUseLightText(user.color) ? "#ffffff" : "#383838",
        flex: "none",
      }}
    >
      {user.image && (
        <img
          style={{ width: size, height: size, backgroundColor: "#000000" }}
          className="rounded-circle object-fit-contain"
          src={large ? user.image.url_large : user.image.url_small}
        />
      )}
      <span className="avatar-text" style={large ? largeFontStyle : {}}>
        {!user.image && user.first_name[0] + user.last_name[0]}
      </span>
    </div>
  );
}
export default Avatar;
