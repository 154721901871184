import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Alert from "./Alert";
import ReactImagePickerEditor from "react-image-picker-editor";
import { uploadImage } from "../awsUtils";

const GroupForm = ({ token, onSuccess, group = null }) => {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [byInvitationOnly, setByInvitationOnly] = useState(true);
  const [showMembers, setShowMembers] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formDisabled, setformDisabled] = useState(false);

  const config = {
    border: "2px solid",
    borderRadius: "2%",
    borderColor: "rgb(0,0,0,.3)",
    language: "en",
    width: 320,
    height: 150,
    objectFit: "cover",
    compressInitial: 90,
    hideDeleteBtn: false,
    hideAddBtn: true,
    hideDownloadBtn: true,
    hideEditBtn: false,
  };

  const [initialUrl, setInitialUrl] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [imageChanged, setImageChanged] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);

  useEffect(() => {
    if (group) {
      setGroupName(group.name);
      setGroupDescription(group.description);
      setByInvitationOnly(group.by_invitation_only);
      setShowMembers(group.show_members);
      setIsPrivate(group.private);
    }
  }, [group]);

  const createGroup = (uuid = null) => {
    const data = {
      name: groupName,
      description: groupDescription,
      by_invitation_only: byInvitationOnly,
      show_members: showMembers,
      private: isPrivate,
      image_uuid: uuid,
      image_changed: imageChanged,
    };
    axios
      .post(`/api/v1/groups`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        onSuccess(response);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Could not save group name");
        }
        setGroupName(group.name);
      });
  };

  const updateGroup = (uuid = null) => {
    const data = {
      name: groupName,
      description: groupDescription,
      by_invitation_only: byInvitationOnly,
      show_members: showMembers,
      private: isPrivate,
      image_uuid: uuid,
      image_changed: imageChanged,
    };

    axios
      .put(`/api/v1/groups/${group.id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Could not save group");
        }
        setGroupName(group.name);
        setGroupDescription(group.description);
      });
  };

  return (
    <>
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      {!isEditingImage && group && group.image && (
        <>
          <img
            src={group.image.url_large}
            width={config.width}
            height={config.height}
            style={{ objectFit: "cover" }}
          />
          <button
            className="btn"
            onClick={() => {
              setIsEditingImage(true);
              setImageChanged(true);
            }}
          >
            <i className="fa fa-trash text-medium-dark"></i>
          </button>
        </>
      )}

      {(!group || !group.image || isEditingImage) && (
        <div className="w-100 d-flex justify-content-center mt-2 mb-3">
          {formDisabled && imageUrl ? (
            <img
              src={imageUrl}
              width={config.width}
              height={config.height}
              style={{ objectFit: "cover" }}
            />
          ) : (
            <ReactImagePickerEditor
              config={config}
              imageSrcProp={initialUrl}
              imageChanged={(newDataUri) => {
                console.log(`checking if change was made`);
                let changed = false;
                if (initialUrl == "" && newDataUri) {
                  console.log(
                    `did not have an initial url and now we have a datauri so a change was made`
                  );
                  changed = true;
                } else if (imageUrl && newDataUri != imageUrl) {
                  console.log(
                    "we had an imageUrl but now datauri is different so a change was made"
                  );
                  changed = true;
                }
                if (changed) {
                  setImageChanged(true);
                }
                setImageUrl(newDataUri);
              }}
            />
          )}
        </div>
      )}

      <input
        type="text"
        className="form-control mb-4"
        placeholder="Name"
        aria-label="name"
        aria-describedby="basic-addon1"
        value={groupName}
        disabled={formDisabled}
        onChange={(e) => setGroupName(e.target.value)}
      ></input>

      <textarea
        type="text"
        className="form-control mb-4"
        style={{ minHeight: "80px" }}
        placeholder="Description"
        aria-label="description"
        aria-describedby="basic-addon1"
        value={groupDescription}
        disabled={formDisabled}
        onChange={(e) => setGroupDescription(e.target.value)}
      ></textarea>

      <Form.Check // prettier-ignore
        type="switch"
        className="mb-4"
        id="by-invitation-only"
        label="Membership is by invitation only"
        checked={byInvitationOnly}
        disabled={formDisabled}
        onChange={(e) => {
          setByInvitationOnly(e.target.checked);
          if (!e.target.checked) {
            setShowMembers(true);
          }
        }}
      />

      <Form.Check // prettier-ignore
        type="switch"
        className="mb-4"
        id="show-members"
        label="Membership list can be seen by everyone"
        checked={showMembers}
        onChange={(e) => setShowMembers(e.target.checked)}
        disabled={formDisabled || !byInvitationOnly}
      />

      <Form.Check // prettier-ignore
        type="switch"
        className="mb-4"
        id="is-private"
        label="Only members can post to this group"
        checked={isPrivate}
        disabled={formDisabled}
        onChange={(e) => setIsPrivate(e.target.checked)}
      />

      <button
        className="btn btn-primary mt-3"
        style={{ minWidth: "220px" }}
        disabled={formDisabled || groupName.length == 0}
        onClick={() => {
          console.log(`clicked save. image changed: ${imageChanged}`);
          if (imageChanged && imageUrl) {
            console.log("uploading group image");
            setformDisabled(true);
            uploadImage(imageUrl).then((result) => {
              const uuid = result.uuid;
              if (group) {
                updateGroup(uuid);
              } else {
                createGroup(uuid);
              }
            });
          } else {
            if (group) {
              updateGroup();
            } else {
              createGroup();
            }
          }
        }}
      >
        {formDisabled ? "Saving..." : group ? "Save" : "Create"}
      </button>
    </>
  );
};
export default GroupForm;
