import React from "react";
import clsx from "clsx";
function Form(props) {
  return (
    <div
      className={`my-form ${props.className ? props.className : ""}`}
      style={props.style ? props.style : {}}
    >
      <form onSubmit={props.handleSubmit}>
        {props.children}
        {props.errorMessage && (
          <div className="alert alert-danger" role="alert">
            {props.errorMessage}
          </div>
        )}
        {props.successMessage && (
          <div className="alert alert-success" role="alert">
            {props.successMessage}
          </div>
        )}
        <div className="d-flex gap-2">
          <button
            type="submit"
            className={`btn btn-primary ${
              props.btnClass ? props.btnClass : ""
            }`}
            disabled={props.submitDisabled}
          >
            {props.submitLabel ? props.submitLabel : "Submit"}
          </button>
          {props.onCancel && (
            <button
              type="button"
              className={`btn btn-medium ${
                props.btnClass ? props.btnClass : ""
              }`}
              onClick={props.onCancel}
            >
              {props.cancelLabel ? props.cancelLabel : "Cancel"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
export default Form;
