import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function useAuthorization() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          "/api/v1/verify_token",
          {},
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then((response) => {
          if (response.data.user) {
            setUser(response.data.user);
          } else {
            console.log("useAuthorization sending to logout");
            console.log(response);
            navigate("/logout");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("api errors:", error);

          if (error && error.response && error.response.status) {
            const status = error.response.status;
            if (status && status == 401) {
              navigate("/logout");
            }
          }
        });
    } else {
      navigate("/logout");
    }
  }, []);

  if (!loading && user) {
    return user;
  }
}
