import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

function Login(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pin = searchParams.get("pin");
  const id = searchParams.get("id");

  useEffect(() => {
    let storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
    console.log(`pin: ${pin}`);
    if (id && pin) {
      // This user has clicked the activate link from their email
      console.log("[Login][constructor] found pin. Will call activate");
      let data = { pin: pin };
      axios
        .post(`/api/v1/users/${id}/activate`, data)
        .then((response) => {
          console.log(response);
          if (response.data?.id && response.data.id == id) {
            setEmail(response.data.email);
            setPassword(pin);
            authenticate(response.data.email, pin);
          } else {
            setErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          console.log("api errors:", error);
          if (error.response && error.response.data.error) {
            setErrorMessage(error.response.data.error);
          }
        });
    }
  }, []);

  const authenticate = (e, p) => {
    let data = { email: e, password: p };
    axios
      .post("/api/v1/authenticate", data, { withCredentials: true })
      .then((response) => {
        if (response.data.auth_token) {
          localStorage.setItem("email", e);
          localStorage.setItem("token", response.data.auth_token);
          if (pin) {
            navigate(`/home?pin=${pin}`);
          } else {
            navigate("/home");
          }
        } else {
          setErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.log("api errors:", error);
        if (error.response && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        }
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    authenticate(email, password);
  };

  return (
    <div className="row justify-content-center bg-light vh-100">
      <div className="col-lg-6 col-md-8 col-sm-12 ml-3 mr-3 mt-3 p-5">
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <h4 className="h4 text-primary">Login</h4>
        <div className="w-100 d-flex justify-content-center">
          <img
            src="https://bluechalupa.s3.amazonaws.com/app/icon.png"
            style={{ width: "250px" }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label text-medium-dark">Email address</label>
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="mb-3">
            <label className="form-label text-medium-dark">Password</label>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              autoComplete="on"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
export default Login;
