function findListWithId(idToFind, list, iteration = 0) {
  if (iteration > 100) {
    return {};
  }
  if (list.id == idToFind) {
    return list;
  }

  let d = null;
  // d = $.grep(droppables, function(e){return e.id == idToFind})[0]
  if (list.to_do_items && list.to_do_items.length > 0) {
    for (let i = 0; i < list.to_do_items.length; i++) {
      if (list.to_do_items[i].converted_list) {
        d = findListWithId(
          idToFind,
          list.to_do_items[i].converted_list,
          iteration + 1
        );
        if (d) {
          break;
        }
      }
    }
  }
  return d;
}

function pluralOrNot(n) {
  if (n > 1) {
    return "s";
  } else {
    return "";
  }
}
function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    var seconds = Math.round(elapsed / 1000);
    // return seconds + " second" + pluralOrNot(seconds) + " ago";
    return "Just now";
  } else if (elapsed < msPerHour) {
    var minutes = Math.round(elapsed / msPerMinute);
    return minutes + " minute" + pluralOrNot(minutes) + " ago";
  } else if (elapsed < msPerDay) {
    var hours = Math.round(elapsed / msPerHour);
    return hours + " hour" + pluralOrNot(hours) + " ago";
  } else if (elapsed < msPerMonth) {
    var days = Math.round(elapsed / msPerDay);
    return days + " day" + pluralOrNot(days) + " ago";
  } else if (elapsed < msPerYear) {
    var months = Math.round(elapsed / msPerMonth);
    return months + " month" + pluralOrNot(months) + " ago";
  } else {
    var years = Math.round(elapsed / msPerYear);
    return years + " year" + pluralOrNot(years) + " ago";
  }
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function shouldUseLightText(backgroundColor) {
  var rgb = hexToRgb(backgroundColor);
  if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) return false;
  else {
    return true;
  }
}

// This will return a string with /n replaced with <br />
// and any link (http://...) with an <a href>...</a>
function parseMessage(message, max) {
  // First replace all \n with <br />
  let str = message.replaceAll("\n", "<br />");
  // Next, replace all links with an <a>
  str = str.replace(/http[a-zA-Z0-9&+#:\/=_?.-]*/g, (url) => {
    const isImage = url.match(/.jpeg|.jpg|.gif|.png/g);
    if (isImage) {
      return ` <img src='${url}'/> `;
    }

    let title = url.replace("http://", "");
    title = title.replace("https://", "");
    if (title.length > max) {
      title = title.substr(0, max) + "...";
    }
    return ` <a href='${url}' target='_blank' rel='noopener noreferrer'>${title}</a> `;
  });
  return str;
}
function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

exports.parseMessage = parseMessage;
exports.shouldUseLightText = shouldUseLightText;
exports.timeDifference = timeDifference;
exports.findListWithId = findListWithId;
exports.validateEmail = validateEmail;
