import React, { useState } from "react";
import axios from "axios";
import Form from "../Form";

function Email(props) {
  const [email, setEmail] = useState(props.user.email);
  const [profileErrorMessage, setProfileErrorMessage] = useState("");
  const [profileSuccessMessage, setProfileSuccessMessage] = useState("");

  const handleUpdateProfile = (event) => {
    event.preventDefault();
    setProfileErrorMessage("");
    setProfileSuccessMessage("");
    let data = { email: email };
    axios
      .put(`/api/v1/users/${props.user.id}`, data, {
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((response) => {
        setProfileSuccessMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        setProfileErrorMessage("Encountered an error");
      });
  };
  {
    /* <div className="mb-3">
          <label className="form-label">First name</label>
          <input value={firstName} onChange={e => {setFirstName(e.target.value)}} type="text" className="form-control" id="first_name" aria-describedby="first_name" />
        </div>
        <div className="mb-3">
          <label className="form-label">Last name</label>
          <input value={lastName} onChange={e => {setLastName(e.target.value)}} type="text" className="form-control" id="last_name" aria-describedby="last_name" />
        </div> */
  }
  return (
    <>
      <div className="rounded bg-medium-light p-3 rounded my-4">
        <Form
          handleSubmit={handleUpdateProfile}
          successMessage={profileSuccessMessage}
          errorMessage={profileErrorMessage}
          submitLabel="Change Email"
        >
          <div className="mb-3">
            <label className="form-label text-dark">Email address</label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              maxLength={50}
            />
          </div>
        </Form>
      </div>
    </>
  );
}
export default Email;
