import React, { useEffect, useState } from "react";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import axios from "axios";
import Alert from "./Alert";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import clsx from "clsx";
import { timeDifference } from "../utils";

function Alerts() {
  const user = useAuthorization();
  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [alerts, setAlerts] = useState(null);

  useEffect(() => {
    if (user && !alerts) {
      fetchAlerts();
    }
  }, [user]);

  const fetchAlerts = () => {
    setErrorMessage(null);
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    axios
      .get(`/api/v1/users/${user.id}/alerts`, {
        headers: { Authorization: `Bearer ${t}` },
      })
      .then((response) => {
        console.log(response.data);
        setAlerts(response.data);
      })
      .catch((error) => {
        console.log("error");
        setErrorMessage(error.message);
      });
  };

  const updateAlert = (alert) => {
    console.log("should update");
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    const data = { viewed: true };
    axios
      .put(`/api/v1/users/${user.id}/alerts/${alert.id}`, data, {
        headers: { Authorization: `Bearer ${t}` },
      })
      .then((response) => {
        // No need to update state
        // User clicked row and we are being taken to alert url
        // when user returns, this state will be refreshed
      })
      .catch((error) => {
        // console.log("error");
        // setErrorMessage(error.message);
      });
  };

  const deleteAlert = (alert) => {
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    axios
      .delete(`/api/v1/users/${user.id}/alerts/${alert.id}`, {
        headers: { Authorization: `Bearer ${t}` },
      })
      .then((response) => {
        fetchAlerts();
      })
      .catch((error) => {
        // console.log("error");
        // setErrorMessage(error.message);
      });
  };

  const clearAll = () => {
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    axios
      .delete(`/api/v1/users/${user.id}/alerts/clear_all`, {
        headers: { Authorization: `Bearer ${t}` },
      })
      .then((response) => {
        fetchAlerts();
      })
      .catch((error) => {
        // console.log("error");
        // setErrorMessage(error.message);
      });
  };

  const alertRowContent = (alert) => (
    <>
      {alert.creator && <Avatar user={alert.creator} />}

      <div className="px-2 d-flex flex-column flex-grow-1 text-start">
        <span className="text-medium">
          <small>
            {timeDifference(Date.now(), Date.parse(alert.created_at))}
          </small>
        </span>
        <h6 className="m-0">{alert.message}</h6>
      </div>
      <div className="d-flex gap-2">
        <button
          className="btn"
          onClick={(evt) => {
            evt.preventDefault();
            deleteAlert(alert);
          }}
        >
          <i className="fa fa-trash text-medium-dark"></i>
        </button>
      </div>
    </>
  );
  const alertRow = (alert) => (
    <div
      key={alert.id}
      className={clsx({
        "rounded p-1 my-2": true,
        "bg-white": !alert.viewed,
        "bg-light-blue": alert.viewed,
      })}
    >
      {alert.url ? (
        <Link
          className={clsx({
            "btn btn-lg d-flex align-items-center w-100": true,
          })}
          style={{ borderRadius: "0" }}
          to={alert.url ? alert.url : ""}
          onClick={() => updateAlert(alert)}
        >
          {alertRowContent(alert)}
        </Link>
      ) : (
        <button
          className={clsx({
            "btn btn-lg d-flex align-items-center w-100": true,
          })}
          style={{ borderRadius: "0" }}
          onClick={() => {
            updateAlert(alert);
            fetchAlerts();
          }}
        >
          {alertRowContent(alert)}
        </button>
      )}
    </div>
  );

  return (
    <div className="bg-light">
      <Navbar previousUrl={"/home"} title="Manage Alerts" />
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      <div
        className="row d-flex justify-content-center vh-100 bg-light px-2"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="col-12 col-md-10 col-lg-7 mt-2">
          {alerts && alerts.length > 0 && (
            <div className="d-flex justify-content-end">
              <button className="btn" onClick={() => clearAll()}>
                <i className="fa fa-trash text-medium-dark"></i>
                <span className="mx-2">Clear All</span>
              </button>
            </div>
          )}

          {alerts &&
            alerts.length > 0 &&
            alerts.map((alert) => {
              return alertRow(alert);
            })}
          {alerts && alerts.length == 0 && (
            <p className="text-center">You have no alerts</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default Alerts;
