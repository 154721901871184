import React, { useEffect, useState } from "react";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import Avatar from "./Avatar";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "./Alert";

function Mutes() {
  const user = useAuthorization();
  const [token, setToken] = useState(null);
  const [mutes, setMutes] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    fetchMutes();
  }, []);

  const fetchMutes = () => {
    setErrorMessage(null);
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    axios
      .get(`/api/v1/mutes`, {
        headers: { Authorization: `Bearer ${t}` },
      })
      .then((response) => {
        console.log(response.data);
        setMutes(response.data.mutes);
      })
      .catch((error) => {
        console.log("error");
        setErrorMessage(error.message);
      });
  };

  const unmuteUser = (mutedUser) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    const data = { muted_user_id: mutedUser.id, active: false };
    axios
      .put(`/api/v1/mutes/${mutedUser.id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSuccessMessage(
          `${mutedUser.first_name} ${mutedUser.last_name} was unmuted`
        );
        fetchMutes();
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          console.log(error.response);
          setErrorMessage(error.response.data.error);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage(error.message);
        }
        // console.log(error.config);
      });
  };
  return (
    <div className="bg-light">
      <Navbar previousUrl={"/home"} title="Manage Muted Users" />
      {successMessage && (
        <Alert
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          type="success"
        />
      )}
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      <div
        className="row d-flex justify-content-center vh-100 bg-light px-2"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="col-12 col-md-10 col-lg-7 mt-2">
          {mutes && mutes.length == 0 && (
            <h5 className="text-center">You have not muted anyone.</h5>
          )}
          {mutes &&
            mutes.length > 0 &&
            mutes.map((m) => {
              return (
                <div key={m.id} className="bg-white rounded p-1 my-2">
                  <button
                    className="btn btn-lg bg-white d-flex align-items-center w-100"
                    style={{ borderRadius: "0" }}
                    onClick={() => setSelectedUser(m.user)}
                  >
                    <Avatar user={m.user} />
                    <div className="px-2 d-flex flex-column flex-grow-1 text-start">
                      <h6 className="m-0">
                        {m.user.first_name + " " + m.user.last_name}
                      </h6>
                      <span
                        className="small m-0 text-start text-medium"
                        style={{ fontSize: "14px" }}
                      >
                        {m.user.screen_name}
                      </span>
                    </div>
                    <i className="fa fa-bell-slash text-medium-dark"></i>
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      <Modal show={selectedUser} onHide={() => setSelectedUser(null)}>
        <Modal.Body>{`Unmute ${selectedUser?.first_name} ${selectedUser?.last_name}?`}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            variant="secondary"
            onClick={() => setSelectedUser(null)}
          >
            No
          </button>
          <button
            className="btn btn-primary"
            variant="primary"
            onClick={() => {
              unmuteUser(selectedUser);
              setSelectedUser(null);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Mutes;
