import React from "react";
import clsx from "clsx";

function Tag({ title, bold, onClose, onClick, noAt = false, className = "" }) {
  const containerClasses = clsx(
    {
      " text-medium-dark px-3 py-1 d-flex align-items-center gap-2 border-0": true,
    },
    { "bg-light text-medium-dark": !bold },
    { "bg-primary text-white": bold }
  );
  const containerStyles = { fontSize: "14px", borderRadius: "0.9rem" };

  return (
    <button
      type="button"
      onClick={(e) => (onClick ? onClick(e) : onClose ? onClose() : {})}
      className={containerClasses}
      style={containerStyles}
    >
      <span>
        {!noAt && "@"}
        {title}
      </span>
      {onClose && <i className="fa fa-close text-medium-dark"></i>}
    </button>
  );
}

export default Tag;
