import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import EditAvatar from "./user/EditAvatar";
import ScreenName from "./user/ScreenName";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import axios from "axios";
import UserStats from "./UserStats";
import clsx from "clsx";

function User() {
  const { id, groupId } = useParams();
  const my_user = useAuthorization();
  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  const [token, setToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editing, setEditing] = useState(false); // for your own page
  const backUrl = searchParams.get("back");

  useEffect(() => {
    console.log("constructor");
    const localToken = localStorage.getItem("token");
    setToken(localToken);
    if (id && my_user) {
      if (id == my_user.id) {
        setUser(my_user);
      } else {
        axios
          .get(`/api/v1/users/${id}`, {
            headers: { Authorization: `Bearer ${localToken}` },
          })
          .then((response) => {
            console.log(response);
            setUser(response.data.user);
          })
          .catch((error) => {
            console.log("error");
          });
      }
      // Either way, get the user_data
      const data = { posted_by_users: JSON.stringify([id]) };
      axios
        .get(`/api/v1/posts`, {
          params: data,
          headers: { Authorization: `Bearer ${localToken}` },
        })
        .then((response) => {
          console.log(response);
          setUserData(response.data.user_data);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [id, my_user]);

  const loadUser = () => {
    axios
      .get(`/api/v1/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("user loadUser");
        console.log(response.data.user);
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  return (
    <>
      {user && token && (
        <>
          <Navbar
            previousUrl={backUrl ? backUrl : "/home"}
            title={user.id == my_user.id ? "Me" : "User"}
          />
          <div
            className={clsx({
              "row d-flex justify-content-center px-2 vh-100": true,
              "bg-light": !editing,
            })}
          >
            <div className="col-12 col-md-10 col-lg-7 py-2">
              {user.id == my_user.id && editing ? (
                <>
                  <EditAvatar token={token} user={user} loadUser={loadUser} />

                  <ScreenName token={token} user={user} loadUser={loadUser} />

                  <div className="w-100 d-flex justify-content-center">
                    <button
                      className="btn btn-primary mt-4"
                      style={{ minWidth: "190px" }}
                      onClick={() => setEditing(false)}
                    >
                      Done
                    </button>
                  </div>
                </>
              ) : (
                <div className="d-flex flex-column align-items-center">
                  {userData && <UserStats user={user} userData={userData} />}
                  {user.id == my_user.id && !editing && (
                    <button
                      className="btn btn-primary mt-4"
                      style={{ minWidth: "190px" }}
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default User;
