import React, { useEffect, useState } from "react";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import axios from "axios";
import Alert from "./Alert";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import GroupForm from "./GroupForm";

function Groups() {
  const user = useAuthorization();
  const [token, setToken] = useState(null);
  const [myGroups, setMyGroups] = useState();
  const [groups, setGroups] = useState();
  const [invitations, setInvitations] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    setErrorMessage(null);
    let t;
    if (!token) {
      const localToken = localStorage.getItem("token");
      setToken(localToken);
      t = localToken;
    } else {
      t = token;
    }
    axios
      .get(`/api/v1/groups`, {
        headers: { Authorization: `Bearer ${t}` },
        params: { bypass_private: true },
      })
      .then((response) => {
        console.log(response.data);
        setInvitations(response.data.invitations);
        setMyGroups(response.data.my_groups);
        setGroups(response.data.groups);
      })
      .catch((error) => {
        console.log("error");
        setErrorMessage(error.message);
      });
  };

  const groupRow = (g) => (
    <div key={g.id} className="bg-white rounded p-1 my-2">
      <Link
        className="btn btn-lg bg-white d-flex align-items-center w-100"
        style={{ borderRadius: "0" }}
        to={`/groups/${g.id}`}
      >
        <div className="px-2 d-flex flex-column flex-grow-1 text-start">
          <h6 className="m-0">{g.name}</h6>
        </div>
        <div className="d-flex gap-2">
          {g.by_invitation_only && (
            <i className="fa fa-lock text-medium-dark"></i>
          )}
          <i className="fa fa-group text-medium-dark"></i>
        </div>
      </Link>
    </div>
  );

  const displayGroups = () => (
    <>
      <h6 className="text-medium-dark">My Groups</h6>
      <div className="mb-3">
        {myGroups && (
          <>
            {myGroups.length > 0 ? (
              myGroups.map((g) => {
                return groupRow(g);
              })
            ) : (
              <span>You have no groups</span>
            )}
          </>
        )}
      </div>
      <button
        className="mb-5 btn btn-primary"
        onClick={() => setShowAddGroupModal(true)}
      >
        Create Group
      </button>
      <div className="mb-5">
        <div className="d-flex">
          <h6 className="text-medium-dark">Invitations</h6>
          {invitations && invitations.length > 0 && (
            <div
              className="mx-2 rounded-circle d-flex justify-content-center align-items-center text-white"
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#d51111",
                fontSize: "11px",
                pointerEvents: "none",
              }}
            >
              {invitations.length}
            </div>
          )}
        </div>
        {invitations && (
          <>
            {invitations.length > 0 ? (
              invitations.map((gu) => {
                return groupRow(gu.group);
              })
            ) : (
              <span className="text-medium">You have no invitations</span>
            )}
          </>
        )}
      </div>
      <h6 className="text-medium-dark">Other Groups</h6>
      {groups && (
        <>
          {groups.length > 0 ? (
            groups.map((g) => {
              return groupRow(g);
            })
          ) : (
            <span>There are no other groups</span>
          )}
        </>
      )}
    </>
  );
  return (
    <div className="bg-light">
      <Navbar previousUrl={"/home"} title="Manage Groups" />
      {successMessage && (
        <Alert
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          type="success"
        />
      )}
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      <div
        className="row d-flex justify-content-center vh-100 bg-light px-2"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="col-12 col-md-10 col-lg-7 mt-2">
          {groups && myGroups && displayGroups()}
        </div>
      </div>
      {token && (
        <Modal
          show={showAddGroupModal}
          onHide={() => {
            setShowAddGroupModal(false);
          }}
        >
          <Modal.Header closeButton>Create New Group</Modal.Header>
          <Modal.Body>
            <GroupForm
              token={token}
              onSuccess={() => {
                setShowAddGroupModal(false);
                fetchGroups();
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
export default Groups;
