import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const createPresignedUrlWithClient = ({ credentials, region, bucket, key }) => {
  const client = new S3Client({ credentials, region });
  const command = new PutObjectCommand({ Bucket: bucket, Key: key });
  return getSignedUrl(client, command, { expiresIn: 3600 });
};

function put(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        headers: {
          "Content-Type": data.type,
        },
      })
      .then((response) => {
        console.log("upload finished");
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          // setErrorMessage(error.response.data.error);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an Error
        }
        reject();
      });
  });
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const upload = async (file, filename, uuid, type = "images") => {
  console.log(`uploadFile. filename: ${filename}`);

  const S3_BUCKET = process.env.AWS_BUCKET;
  const REGION = process.env.AWS_REGION;

  const creds = {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  };
  const clientUrl = await createPresignedUrlWithClient({
    credentials: creds,
    region: REGION,
    bucket: S3_BUCKET,
    key: `uploads/${process.env.RAILS_ENV}/${type}/${uuid}/${filename}`,
  });
  const response = await put(clientUrl, file);
  return response;
};

const resizeFile = async (file, large) => {
  return new Promise((resolve) => {
    const max = large ? 2000 : 600;
    Resizer.imageFileResizer(
      file,
      max,
      max,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
};

export const uploadImage = async (data, imageUuid = null) => {
  return new Promise(async (resolve) => {
    const uuid = imageUuid ? imageUuid : uuidv4();
    const blob = dataURLtoBlob(data);
    // console.log("First uploading original");
    // await upload(blob, "original", uuid);
    console.log(`Uploading image: ${uuid}`);
    resizeFile(blob, true)
      .then((uri) => {
        return new Promise((resolveLargeUpload) => {
          (async (uri, uuid) => {
            const response = await upload(uri, "large.jpeg", uuid);
            resolveLargeUpload(response, uuid);
          })(uri, uuid);
        });
      })
      .then((response, uuid) => {
        console.log(`resizing to small ${uuid}`);
        console.log(response);
        return resizeFile(blob, false);
      })
      .then((uri) => {
        return new Promise((resolveSmallUpload) => {
          (async () => {
            const response = await upload(uri, "small.jpeg", uuid);
            resolveSmallUpload(response);
          })();
        });
      })
      .then((response) => {
        console.log("completed small upload");
        console.log(response);
        resolve({ success: true, uuid: uuid });
      });
  });
};

export const uploadVideo = async (videoData, ext, videoUuid = null) => {
  const uuid = videoUuid ? videoUuid : uuidv4();
  return new Promise((resolve) => {
    return new Promise((resolveVideoUpload) => {
      (async (uri, uuid) => {
        const response = await upload(uri, `video.${ext}`, uuid, "videos");
        resolveVideoUpload(response);
      })(videoData, uuid);
    }).then((response) => {
      console.log("completed video upload");
      console.log(response);
      resolve({ success: true, uuid: uuid });
    });
  });
};
