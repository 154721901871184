import React, { useState, useEffect } from "react";
import ReactImagePickerEditor from "react-image-picker-editor";

function PostFormImage({
  config,
  initialUrl,
  imagePosition,
  updateImage,
  addMultipleImages,
  uuid = null,
}) {
  const [imageUrl, setImageUrl] = useState("");
  const [imageChanged, setImageChanged] = useState(false);

  useEffect(() => {
    if (initialUrl == "" && imageUrl) {
      setImageUrl("");
    }
  }, [initialUrl]);

  const handleMultipleFiles = (e) => {
    addMultipleImages(e.target.files);
  };
  return (
    <>
      {(imageUrl || initialUrl != "") && (
        <ReactImagePickerEditor
          config={config}
          imageSrcProp={initialUrl}
          imageChanged={(newDataUri) => {
            let changed = false;
            if (imageUrl && !imageChanged) {
              console.log(`checking if change was made`);
              // imageUrl was already set at least once
              if (newDataUri != imageUrl) {
                console.log("image has changed");
                setImageChanged(true);
                changed = true;
              }
            }
            setImageUrl(newDataUri);

            const image = {
              url: newDataUri,
              position: imagePosition,
              hasChanged: changed,
              initialUrl: initialUrl,
              uuid: uuid,
            };
            console.log(
              `about to notify of an image update. url: ${image.url} imageUrl: ${imageUrl}`
            );
            if (image.url != "" || (image.url == "" && imageUrl != "")) {
              // image has been updated or deleted
              updateImage(image);
            }
          }}
        />
      )}
      {!imageUrl && (
        <div
          className="bg-white d-flex justify-content-center align-items-center"
          style={config}
        >
          <label htmlFor="multi-image-input">
            <i className="fa fa-camera form-icon"></i>
          </label>

          <input
            className="d-none"
            type="file"
            id="multi-image-input"
            multiple="multiple"
            name="multi-image"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              handleMultipleFiles(e);
            }}
          />
        </div>
      )}
    </>
  );
}

export default PostFormImage;
