import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import clsx from "clsx";
import Avatar from "../Avatar";

const classesForType = {
  heart: "fa fa-heart text-red",
  fire: "fa fa-fire text-orange",
  thumbs_up: "fa fa-thumbs-up text-secondary",
  thumbs_down: "fa fa-thumbs-down text-secondary",
  poo: "fa fa-poop text-brown",
  surprise: "fa fa-face-surprise text-yellow bg-black rounded-circle",
  smile: "fa fa-face-smile text-yellow bg-black rounded-circle",
  beam: "fa fa-face-smile-beam text-yellow bg-black rounded-circle",
  sad: "fa fa-face-sad-cry text-yellow bg-black rounded-circle",
  meh: "fa fa-face-meh text-yellow bg-black rounded-circle",
  blank: "fa fa-face-meh-blank text-yellow bg-black rounded-circle",
  laugh: "fa fa-face-laugh-beam text-yellow bg-black rounded-circle",
  stars: "fa fa-face-grin-stars text-yellow bg-black rounded-circle",
  hearts: "fa fa-face-grin-hearts text-yellow bg-black rounded-circle",
  flushed: "fa fa-face-flushed text-yellow bg-black rounded-circle",
  frown: "fa fa-face-frown text-yellow bg-black rounded-circle",
  grimace: "fa fa-face-grimace text-yellow bg-black rounded-circle",
  dizzy: "fa fa-face-dizzy text-yellow bg-black rounded-circle",
  wink: "fa fa-face-smile-wink text-yellow bg-black rounded-circle",
  eye_roll: "fa fa-face-rolling-eyes text-yellow bg-black rounded-circle",
  angry: "fa fa-face-angry text-angry-red bg-black rounded-circle",
};

function ReactionsView({
  user,
  token,
  setRefreshPostId,
  post = null,
  comment = null,
}) {
  const [showModal, setShowModal] = useState(false);
  const [reactionsByType, setReactionsByType] = useState();
  const [reactionsByUser, setReactionsByUser] = useState();
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [reactions, setReactions] = useState(null);

  useEffect(() => {
    if (post && post.post_reactions) {
      setReactions(post.post_reactions);
    }
  }, [post]);

  useEffect(() => {
    if (comment && comment.comment_reactions) {
      setReactions(comment.comment_reactions);
    }
  }, [comment]);

  useEffect(() => {
    if (reactions) {
      const hashByType = {};
      const hashByUser = {};
      reactions.map((r) => {
        if (hashByType[r.reaction_type.key]) {
          // We already have at least one of this type
          hashByType[r.reaction_type.key]["total"] =
            hashByType[r.reaction_type.key]["total"] + 1;
          if (r.created_by == user.id) {
            hashByType[r.reaction_type.key]["includes_me"] = true;
            hashByType[r.reaction_type.key]["reaction_id"] = r.id;
          }
        } else {
          // First of kind
          hashByType[r.reaction_type.key] = {
            total: 1,
            includes_me: false,
          };
          if (r.created_by == user.id) {
            hashByType[r.reaction_type.key]["includes_me"] = true;
            hashByType[r.reaction_type.key]["reaction_id"] = r.id;
          }
        }

        if (hashByUser[r.created_by]) {
          // We already have this user. Add the reaction type
          hashByUser[r.created_by]["types"].push(r.reaction_type.key);
        } else {
          // Add the user
          hashByUser[r.created_by] = {
            user: r.creator,
            types: [r.reaction_type.key],
          };
        }
      });
      setReactionsByType(hashByType);
      setReactionsByUser(hashByUser);
    }
  }, [reactions]);

  const addReaction = (type) => {
    setShowModal(false);
    const data = { key: type };
    if (post) {
      data.post_id = post.id;
    } else if (comment) {
      data.comment_id = comment.id;
    }
    const path = post ? "post_reactions" : "comment_reactions";
    axios
      .post(`/api/v1/${path}`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        let post_id = response.data.post_id;
        if (post) {
          post_id = post.id;
        }
        setRefreshPostId(post_id);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.error);
        console.log("error");
        console.log(error);
      });
  };

  const removeReaction = (type) => {
    setShowModal(false);

    const reaction_id = reactionsByType[type]["reaction_id"];
    const path = post ? "post_reactions" : "comment_reactions";
    axios
      .delete(`/api/v1/${path}/${reaction_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        let post_id = response.data.post_id;
        if (post) {
          post_id = post.id;
        }
        setRefreshPostId(post_id);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.error);
        console.log("error");
        console.log(error);
      });
  };

  const toggleReaction = (k) => {
    if (reactionsByType[k]["includes_me"]) {
      removeReaction(k);
    } else {
      addReaction(k);
    }
  };

  return (
    <>
      {reactions && user && (
        <div
          className={clsx({
            "d-flex flex-wrap": true,
            "align-items-center": reactions.length == 0,
            "px-2 gap-2": reactions.length > 0,
          })}
        >
          {reactions.length == 0 && (
            <small className="text-medium" style={{ marginLeft: "10px" }}>
              Add Reaction
            </small>
          )}
          {reactionsByType &&
            Object.keys(reactionsByType).length > 0 &&
            Object.keys(reactionsByType).map((k, i) => (
              <div key={`reaction-${k}-${i}`}>
                <ReactionButton
                  type={k}
                  total={reactionsByType[k]["total"]}
                  onClick={() => setShowUsageModal(true)}
                  includes_me={reactionsByType[k]["includes_me"]}
                />
              </div>
            ))}
          <div className="d-flex justify-content-end" style={{ width: "40px" }}>
            <button
              className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "30px", height: "30px" }}
              onClick={() => setShowModal(true)}
            >
              <i className="fa fa-plus" style={{ fontSize: "20px" }}></i>
            </button>
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <div className="d-flex flex-wrap gap-2 px-2 justify-content-between">
            {Object.keys(classesForType).map((type, i) => (
              <div key={`selectable-reaction-${type}-${i}`}>
                <ReactionButton type={type} onClick={() => addReaction(type)} />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showUsageModal} onHide={() => setShowUsageModal(false)}>
        <Modal.Header closeButton>Reactions</Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-2 px-2">
            {reactionsByUser &&
              Object.keys(reactionsByUser).length > 0 &&
              Object.keys(reactionsByUser).map((user_id, i) => {
                const reactor = reactionsByUser[user_id]["user"];
                const types = reactionsByUser[user_id]["types"];
                return (
                  <div
                    key={`usage-${user_id}-${i}`}
                    className="d-flex gap-2 align-items-center"
                  >
                    <Avatar user={reactor} />
                    <span
                      style={{ flexGrow: "1" }}
                    >{`${reactor.first_name} ${reactor.last_name}`}</span>
                    <div
                      className="d-flex flex-wrap gap-2 justify-content-end"
                      style={{ flex: "3" }}
                    >
                      {types.map((type) => (
                        <div key={`usage-reaction-${type}`}>
                          <ReactionButton
                            type={type}
                            includes_me={user_id == user.id}
                            show_x={true}
                            onClick={
                              user_id == user.id
                                ? () => toggleReaction(type)
                                : null
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const ReactionButton = ({
  type,
  total,
  onClick,
  includes_me = false,
  show_x = false,
}) => {
  return (
    <button
      className={clsx({
        "btn d-flex align-items-center position-relative": true,
        "border rounded-pill py-0 gap-1": total || total == 0,
        "bg-lighter": includes_me,
      })}
      style={{ height: "30px", minWidth: total ? "70px" : "50px" }}
      onClick={onClick}
    >
      <i
        className={classesForType[type]}
        style={{ fontSize: "22px", marginRight: "5px" }}
      ></i>
      {(total || total == 0) && <span>{total}</span>}
      {includes_me && show_x && (
        <i
          className="fa fa-times position-absolute text-medium"
          style={{ fontSize: "12px", top: "2px", right: "2px" }}
        ></i>
      )}
    </button>
  );
};
export default ReactionsView;
