import React from "react";
import Avatar from "./Avatar";

function UserStats({ user, userData }) {
  return (
    <>
      <Avatar user={user} large={true} />
      <h5 className="mt-3 text-dark">{`${user.first_name} ${user.last_name}`}</h5>
      <p className="text-medium-dark">{user.screen_name}</p>
      <div className="mt-2 text-center">
        <p className="text-medium-dark mb-1">
          Total Posts:{" "}
          <span className="text-secondary fw-bold">{userData.total_posts}</span>
        </p>

        <p className="text-medium-dark mb-1">
          Total Comments:{" "}
          <span className="text-secondary fw-bold">
            {userData.total_comments}
          </span>
        </p>
      </div>
    </>
  );
}
export default UserStats;
