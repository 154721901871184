import React, { useState, useEffect } from "react";
import axios from "axios";
import clsx from "clsx";

function PollView({ post, user, token, setRefreshPostId }) {
  const [initialized, setInitialized] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [totalsByChoiceId, setTotalsByChoiceId] = useState();
  const [percentageByChoiceId, setPercentageByChoiceId] = useState();
  const [submittingChoiceId, setSubmittingChoiceId] = useState(0);

  const _colors = [
    "#8ce5e5", // turqois
    "#689fed", // blue
    "#f5eb7a", // yellow
    "#c29fef", // purple
    "#f5d0b5", // orange
    "#bff5b5", // lime green
  ];

  useEffect(() => {
    if (post && post.poll.poll_selections) {
      // People have answered this poll
      const userHasSelected = post.poll.poll_selections.find(
        (selection) => selection.user_id == user.id
      );
      setShowResults(userHasSelected);

      // Create hash of percentages
      const hashTotals = {};
      const hashPercs = {};
      const total = post.poll.poll_selections.length;
      post.poll.poll_selections.map((selection) => {
        if (!hashTotals[selection.poll_choice_id]) {
          hashTotals[selection.poll_choice_id] = 1;
        } else {
          hashTotals[selection.poll_choice_id] =
            hashTotals[selection.poll_choice_id] + 1;
        }
        hashPercs[selection.poll_choice_id] = `${Math.round(
          (hashTotals[selection.poll_choice_id] / total) * 100
        )}%`;
      });
      setTotalsByChoiceId(hashTotals);
      setPercentageByChoiceId(hashPercs);
    }
    setInitialized(true);
  }, [post]);

  let colors = [];
  const getColor = () => {
    if (colors.length == 0) {
      // This is my attempt to randomize the starting point of colors
      // but to keep it the same for this post
      // Options 0 through 5
      const idString = post.poll.id.toString();
      const lastDigitOfId = idString.charAt(idString.length - 1);
      // So far, this will get 0 through 9
      let n = parseInt(lastDigitOfId);
      if (n > 5) {
        n = 10 - n;
      }
      // Now we should have 0 through 5
      if (n == 0) {
        colors = _colors.map((c) => c);
      } else {
        colors = _colors.slice(n).map((c) => c);
        _colors.slice(0, n).map((c) => {
          colors.push(c);
        });
      }
    }
    const color = colors.pop();
    return color;
  };
  const handleSelection = (choice) => {
    setSubmittingChoiceId(choice.id);

    const data = { poll_id: post.poll.id, poll_choice_id: choice.id };
    axios
      .post(`/api/v1/poll_selections`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setInitialized(false);
        setRefreshPostId(post.id);
      })
      .catch((error) => {
        console.log("Could not save poll selection");
      });
  };
  const getPercentForChoice = (choice) => {
    let perc = "0%";
    const hashedPerc = percentageByChoiceId[choice.id];
    if (hashedPerc) {
      perc = hashedPerc;
    }
    return perc;
  };
  const getTotalForChoice = (choice) => {
    let tot = "0 votes";
    const hashedTotal = totalsByChoiceId[choice.id];
    if (hashedTotal) {
      if (hashedTotal == 1) {
        tot = `1 vote`;
      } else {
        tot = `${hashedTotal} votes`;
      }
    }
    return tot;
  };
  return (
    <div className="m-4 shadow-lg border border-rounded">
      {initialized && (
        <>
          <div className="poll-header p-3 text-center text-white">
            <h3 className="m-0">{`Poll${showResults ? " Results" : ""}`}</h3>
          </div>
          <div className="p-4">
            <h5 className="text-center mb-3">{post.poll.message}</h5>
            {showResults ? (
              <>
                {post.poll.poll_choices &&
                  post.poll.poll_choices.map((choice) => (
                    <div
                      key={`poll-${post.poll.id}-choice-${choice.id}`}
                      className="position-relative d-flex gap-1 align-items-center mb-2"
                      style={{ minHeight: "50px" }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <div
                          className="position-absolute"
                          style={{
                            backgroundColor: getColor(),
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: getPercentForChoice(choice),
                          }}
                        />
                        <p className="p-2 m-0 position-relative">
                          {choice.text}
                        </p>
                      </div>
                      <div
                        className="position-relative"
                        style={{ textAlign: "right", paddingRight: "4px" }}
                      >
                        <span className="d-block small">
                          {getPercentForChoice(choice)}
                        </span>
                        <span
                          className="d-block small"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {getTotalForChoice(choice)}
                        </span>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {post.poll.poll_choices &&
                  post.poll.poll_choices.map((choice) => (
                    <div
                      key={`poll-choice-${choice.id}`}
                      className="d-flex gap-3 align-items-center"
                      style={{ minHeight: "50px" }}
                      onClick={() => handleSelection(choice)}
                    >
                      <i
                        className={clsx({
                          "fa-regular": true,
                          "fa-square": submittingChoiceId != choice.id,
                          "fa-square-check": submittingChoiceId == choice.id,
                        })}
                        style={{ fontSize: "28px" }}
                      ></i>
                      <p
                        key={`poll-${post.poll.id}-choice-${choice.id}`}
                        className="m-0"
                      >
                        {choice.text}
                      </p>
                    </div>
                  ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
export default PollView;
