import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import axios from "axios";

function PostSideMenu({
  token,
  user,
  post,
  showPostMenu,
  closePostMenu,
  setSuccessMessage,
  setErrorMessage,
}) {
  const muteUser = () => {
    const data = { muted_user_id: post.created_by };
    axios
      .post(`/api/v1/mutes`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        closePostMenu(true, {
          success: true,
          message: `${post.creator.first_name} ${post.creator.last_name} was muted`,
        });
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data.error);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage(error.message);
        }
      });
  };

  const deletePost = () => {
    axios
      .delete(`/api/v1/posts/${post.id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log("success");
        closePostMenu(true, {
          success: true,
          message: `Post was removed`,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          setErrorMessage(error.response.data.error);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage(error.message);
        }
      });
  };
  return (
    <Offcanvas
      show={showPostMenu}
      placement="end"
      onHide={() => closePostMenu(false)}
      className="bg-light"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => closePostMenu(false)}
        ></button>
      </div>
      {user && (
        <Offcanvas.Body className="p-0">
          <div className="d-grid gap-2 mt-3 my-list-menu">
            {post && (
              <>
                {/* <button
                  className="btn bg-white d-flex align-items-center py-3"
                  style={{ borderRadius: "0" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `http://${process.env.REACT_APP_DOMAIN}?post_id=${post.id}`
                    );
                    setSuccessMessage("Successfully copied url");
                    closePostMenu(false);
                  }}
                >
                  <i className="fa fa-share"></i>
                  <span className="mx-2">Copy Post Url</span>
                </button> */}
                {post.created_by == user.id && (
                  <>
                    <Link
                      to={`/posts/${post.id}`}
                      className="btn bg-white d-flex align-items-center py-3"
                      style={{ borderRadius: "0" }}
                    >
                      <i className="fa fa-pencil"></i>
                      <span className="mx-2">Edit Post</span>
                    </Link>
                    <button
                      className="btn bg-white d-flex align-items-center py-3"
                      style={{ borderRadius: "0" }}
                      onClick={() => {
                        deletePost();
                        closePostMenu(true);
                      }}
                    >
                      <i className="fa fa-trash menu-item-icon"></i>
                      <span className="mx-2">Delete Post</span>
                    </button>
                  </>
                )}
                {post.created_by != user.id && (
                  <>
                    <button
                      className="btn bg-white d-flex align-items-center py-3"
                      style={{ borderRadius: "0" }}
                      onClick={() => muteUser()}
                    >
                      <i className="fa fa-bell-slash menu-item-icon"></i>
                      <span className="mx-2">Mute User</span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </Offcanvas.Body>
      )}
    </Offcanvas>
  );
}
export default PostSideMenu;
