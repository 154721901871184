import React, { useState, useEffect } from "react";

function ImageViewer({ images, imageIndex, setImageIndex, onClose }) {
  const [count, setCount] = useState(0);
  const updateOrientation = (event) => {
    setCount(count + 1); // testing this to see if it re-renders properly
  };
  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);
  const reset = () => {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content = "initial-scale=1";
      viewport.content = "width=device-width";
    }
  };
  const offsetWidth = document.body.offsetWidth;
  const s = document.body.clientWidth;
  const offsetHeight = document.body.offsetHeight;
  console.log(` w: ${s}`);
  console.log(`offset width: ${offsetWidth}`);
  return (
    <div
      className="bg-black position-fixed"
      style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
    >
      <div
        className="row d-flex justify-content-center overflow-y-scroll"
        onClick={() => {
          reset();
        }}
      >
        <div
          className="col-12 col-md-10 col-lg-8"
          onClick={() => {
            reset();
          }}
        >
          <div
            className="d-flex w-100 vh-100 align-items-center justify-content-center"
            onClick={() => {
              reset();
            }}
          >
            <img
              src={images[imageIndex].original}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              onClick={() => {
                reset();
              }}
            />
          </div>
        </div>
      </div>

      {images.length > 1 && (
        <div
          className="position-absolute w-100 vh-100 d-flex justify-content-between"
          style={{ top: 0 }}
        >
          <button
            className="btn"
            style={{ fontSize: "29px" }}
            type="button"
            onClick={() => {
              let n = imageIndex - 1;
              if (n < 0) {
                n = images.length - 1;
              }
              setImageIndex(n);
            }}
          >
            <i className="fa fa-chevron-left text-white"></i>
          </button>
          <div
            className="flex-grow-1"
            onClick={() => {
              reset();
            }}
          />
          <button
            className="btn"
            style={{ fontSize: "29px" }}
            type="button"
            onClick={() => {
              let n = imageIndex + 1;
              if (n > images.length - 1) {
                n = 0;
              }
              setImageIndex(n);
            }}
          >
            <i className="fa fa-chevron-right text-white"></i>
          </button>
        </div>
      )}
      <button
        className="btn position-absolute bg-black rounded-circle d-flex justify-content-center align-items-center"
        style={{
          top: `5px`,
          right: `5px`,
          fontSize: "29px",
          width: "44px",
          height: "44px",
        }}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        <i className="fa fa-times text-white"></i>
      </button>
    </div>
  );
}
export default ImageViewer;
