import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "../Form";

function ScreenName(props) {
  const [screenName, setScreenName] = useState(props.user.screen_name);
  const [profileErrorMessage, setProfileErrorMessage] = useState("");
  const [profileSuccessMessage, setProfileSuccessMessage] = useState("");

  const handleUpdateProfile = (event) => {
    event.preventDefault();
    setProfileErrorMessage("");
    setProfileSuccessMessage("");
    let data = { screen_name: screenName };
    axios
      .put(`/api/v1/users/${props.user.id}`, data, {
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((response) => {
        setProfileSuccessMessage(response.data.message);
        props.loadUser();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          setProfileErrorMessage(error.response.data.error);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setProfileErrorMessage(error.message);
        }
      });
  };
  {
    /* <div className="mb-3">
          <label className="form-label">First name</label>
          <input value={firstName} onChange={e => {setFirstName(e.target.value)}} type="text" className="form-control" id="first_name" aria-describedby="first_name" />
        </div>
        <div className="mb-3">
          <label className="form-label">Last name</label>
          <input value={lastName} onChange={e => {setLastName(e.target.value)}} type="text" className="form-control" id="last_name" aria-describedby="last_name" />
        </div> */
  }
  return (
    <>
      <div className="bg-light p-3 rounded my-4">
        <Form
          handleSubmit={handleUpdateProfile}
          successMessage={profileSuccessMessage}
          errorMessage={profileErrorMessage}
          submitLabel="Change Screen Name"
          submitDisabled={!props.user.screen_name_access}
        >
          <div className="mb-3">
            <label className="form-label text-dark">Screen Name</label>
            <p className="small text-dark">
              You can customize this after you become a Rock Star.
            </p>
            <input
              value={screenName}
              onChange={(e) => {
                setScreenName(e.target.value);
              }}
              type="text"
              className="form-control"
              aria-describedby="screenName"
              maxLength={50}
              disabled={!props.user.screen_name_access}
            />
          </div>
        </Form>
      </div>
    </>
  );
}
export default ScreenName;
