import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import axios from "axios";
import GroupStats from "./GroupStats";
import Avatar from "./Avatar";
import Modal from "react-bootstrap/Modal";
import Alert from "./Alert";
import GroupForm from "./GroupForm";
import { useNavigate } from "react-router-dom";

function Group() {
  const { id } = useParams();
  const my_user = useAuthorization();
  const [group, setGroup] = useState();
  const [groupData, setGroupData] = useState();
  const [token, setToken] = useState(null);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const navigate = useNavigate();

  const config = {
    border: "2px solid",
    borderRadius: "2%",
    borderColor: "rgb(0,0,0,.3)",
    language: "en",
    width: 320,
    height: 120,
    objectFit: "cover",
    compressInitial: 90,
    hideDeleteBtn: false,
    hideAddBtn: true,
    hideDownloadBtn: true,
    hideEditBtn: false,
  };

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken);
    if (id) {
      const data = { signed: true };
      axios
        .get(`/api/v1/groups/${id}`, {
          headers: { Authorization: `Bearer ${localToken}` },
          params: data,
        })
        .then((response) => {
          console.log(response.data);
          setGroup(response.data.group);
          console.log(my_user);
          setGroupData(response.data.group_data);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [id, my_user]);

  const removeInvitation = (gu) => {
    axios
      .delete(`/api/v1/groups/${group.id}/users/${gu.user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("invitation removed");
        refreshData();
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const acceptInvitation = () => {
    axios
      .post(
        `/api/v1/groups/${group.id}/users/${my_user.id}/accept_invitation`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.error) {
          setErrorMessage(response.data.error);
        } else {
          refreshData();
        }
      })
      .catch((error) => {
        setErrorMessage("Could not accept invitation");
      });
  };

  const rejectInvitation = () => {
    axios
      .post(
        `/api/v1/groups/${group.id}/users/${my_user.id}/reject_invitation`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.error) {
          setErrorMessage(response.data.error);
        } else {
          refreshData();
        }
      })
      .catch((error) => {
        setErrorMessage("Could not accept invitation");
      });
  };

  const refreshData = () => {
    axios
      .get(`/api/v1/groups/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        setGroup(response.data.group);
        setGroupData(response.data.group_data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const deleteGroup = () => {
    axios
      .delete(`/api/v1/groups/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        navigate("/groups");
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const joinGroup = () => {
    axios
      .post(
        `/api/v1/groups/${id}/users/${my_user.id}/join_group`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response);
        refreshData();
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const renderUsers = () => (
    <div className="w-100 pb-5">
      {group.group_users.map((gu) => (
        <div key={gu.id} className="bg-white rounded p-1 my-2 w-100">
          <Link
            className="btn btn-lg bg-white d-flex align-items-center w-100"
            style={{ borderRadius: "0" }}
            to={`/groups/${group.id}/users/${gu.user.id}?back=/groups/${group.id}`}
          >
            <Avatar user={gu.user} />
            <div className="px-2 d-flex flex-column flex-grow-1 text-start">
              <h6 className="m-0">{`${gu.user.first_name} ${gu.user.last_name}`}</h6>
              <span className="text-medium-dark">{gu.user.screen_name}</span>
            </div>
            {gu.admin && <i className="fa fa-crown text-medium-dark"></i>}
          </Link>
        </div>
      ))}
    </div>
  );

  const renderInvitations = () => (
    <div className="w-100 pb-5">
      {groupData.invitations.map((gu) => (
        <button
          key={gu.id}
          className="btn d-flex align-items-center bg-white rounded p-2 my-2 w-100"
          onClick={() => setSelectedInvitation(gu)}
        >
          <Avatar user={gu.user} />
          <div className="px-2 d-flex flex-column flex-grow-1 text-start">
            <h6 className="m-0">{`${gu.user.first_name} ${gu.user.last_name}`}</h6>
            <span className="text-medium-dark">{gu.user.screen_name}</span>
          </div>
          <div className="p-2">
            <i className="fa fa-trash text-medium-dark"></i>
          </div>
        </button>
      ))}
    </div>
  );

  return (
    <div className="bg-light">
      {group && groupData && (
        <>
          <Navbar
            previousUrl={"/groups"}
            title={
              groupData.is_member
                ? "My Group"
                : groupData.invitations.length > 0
                ? "Invitation"
                : "Other Group"
            }
          />
          {errorMessage && (
            <Alert
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
              type="danger"
            />
          )}
          <div
            className="row d-flex justify-content-center vh-100 bg-light px-2"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <div className="col-12 col-md-10 col-lg-7 mt-2 d-flex flex-column align-items-center">
              {!group ? (
                <h5 className="text-center">Could not load group.</h5>
              ) : (
                <>
                  <GroupStats group={group} groupData={groupData} />

                  {!groupData.is_member && !group.by_invitation_only && (
                    <div className="mb-3 d-flex flex-column align-items-center gap-2 bg-medium-light rounded-2 p-3 w-100">
                      <p>
                        <small>Want to join this group?</small>
                      </p>

                      <button
                        className="btn btn-primary mb-2"
                        style={{ minWidth: "190px" }}
                        onClick={() => joinGroup()}
                      >
                        Join
                      </button>
                    </div>
                  )}

                  {!groupData.is_member && groupData.invitations.length > 0 && (
                    <div className="mb-3 d-flex flex-column align-items-center gap-2 bg-medium-light rounded-2 p-3 w-100">
                      <p>
                        <small>Respond to this invitation:</small>
                      </p>

                      <button
                        className="btn btn-primary mb-2"
                        style={{ minWidth: "190px" }}
                        onClick={() => acceptInvitation()}
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-primary mb-2"
                        style={{ minWidth: "190px" }}
                        onClick={() => rejectInvitation()}
                      >
                        Reject
                      </button>
                    </div>
                  )}
                  {group.show_members ? (
                    <p className="mt-2 text-medium-dark">
                      Members list is visible to everyone
                    </p>
                  ) : (
                    <p className="mt-2 text-medium-dark">
                      Members list is private
                    </p>
                  )}
                  {group.private ? (
                    <p className="mt-2 text-medium-dark">
                      Only members can post to this group
                    </p>
                  ) : (
                    <p className="mt-2 text-medium-dark">
                      Anyone can post to this group
                    </p>
                  )}
                  {groupData.is_member ? (
                    <>
                      {groupData.is_admin && (
                        <div className="d-flex flex-column align-items-center gap-2 bg-medium-light rounded-2 p-3 w-100">
                          <p>
                            <small>
                              <i
                                className="fa fa-crown text-medium-dark"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Admin Panel
                            </small>
                          </p>

                          <button
                            className="btn btn-primary mb-2"
                            style={{ minWidth: "190px" }}
                            onClick={() => setShowEditGroupModal(true)}
                          >
                            Edit Group
                          </button>

                          <Link
                            className="btn btn-primary mb-2"
                            style={{ minWidth: "190px" }}
                            to={`/groups/${group.id}/users`}
                          >
                            Invite User
                          </Link>

                          <p>
                            Pending Invitations ({groupData.invitations.length})
                          </p>
                          {groupData.invitations.length > 0 &&
                            renderInvitations()}

                          <button
                            className="btn btn-primary mb-2"
                            style={{ minWidth: "190px" }}
                            onClick={() => setShowConfirmDelete(true)}
                          >
                            Delete Group
                          </button>
                        </div>
                      )}
                      {renderUsers()}
                    </>
                  ) : (
                    <>{group.show_members && renderUsers()}</>
                  )}
                </>
              )}
            </div>
          </div>
          <Modal
            show={selectedInvitation}
            onHide={() => setSelectedInvitation(null)}
          >
            <Modal.Body>{`Uninvite ${selectedInvitation?.user?.first_name} ${selectedInvitation?.user?.last_name}?`}</Modal.Body>
            <Modal.Footer>
              <button
                className="btn"
                variant="secondary"
                onClick={() => setSelectedInvitation(null)}
              >
                No
              </button>
              <button
                className="btn btn-primary"
                variant="primary"
                onClick={() => {
                  removeInvitation(selectedInvitation);
                  setSelectedInvitation(null);
                }}
              >
                Yes
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showEditGroupModal}
            onHide={() => {
              setShowEditGroupModal(false);
            }}
          >
            <Modal.Header closeButton>Edit Group</Modal.Header>
            <Modal.Body>
              {group && (
                <GroupForm
                  token={token}
                  group={group}
                  onSuccess={() => {
                    setShowEditGroupModal(false);
                    refreshData();
                  }}
                />
              )}
            </Modal.Body>
          </Modal>
          <Modal
            show={showConfirmDelete}
            onHide={() => {
              setShowConfirmDelete(false);
            }}
          >
            <Modal.Header closeButton>Delete this group?</Modal.Header>
            <Modal.Body>
              <button
                className="btn"
                variant="secondary"
                onClick={() => setShowConfirmDelete(false)}
              >
                No
              </button>
              <button
                className="btn btn-primary"
                variant="primary"
                onClick={() => {
                  setShowConfirmDelete(false);
                  deleteGroup();
                }}
              >
                Yes
              </button>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}
export default Group;
