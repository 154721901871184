import React, { forwardRef, useEffect, useState, useRef } from "react";
import { timeDifference } from "../utils";
import ShowMoreText from "react-show-more-text";
import Avatar from "./Avatar";
import Tag from "./Tag";
import CommentsView from "./post/CommentsView";
import PollView from "./post/PollView";
import { parseMessage } from "../utils";
import ReactionsView from "./post/ReactionsView";
import ReactPlayer from "react-player";
import { Gallery } from "react-grid-gallery";
import ImageViewer from "./ImageViewer";

export const PostView = forwardRef(
  (
    {
      post,
      user,
      token,
      openPostMenu,
      containerWidth,
      addPostedToGroup,
      addPostedToUser,
      addPostedByUser,
      setRefreshPostId,
      setShowFloatingButton,
      orderTable,
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState(true);
    const [expandByClick, setExpandByClick] = useState(false);
    const [parsedMessage, setParsedMessage] = useState(null);
    const [messageReady, setMessageReady] = useState(false);
    const loadMessageRef = useRef();
    const maxLines = 15;
    const [images, setImages] = useState(null);
    const [video, setVideo] = useState(null);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [rowHeight, setRowHeight] = useState(300);
    // const [tileViewportStyle, setTileViewportStyle] = useState(null);
    const [count, setCount] = useState(0);

    const updateOrientation = (event) => {
      sizePhotos();
      setCount(count + 1); // testing this to see if it re-renders properly
    };
    useEffect(() => {
      window.addEventListener("orientationchange", updateOrientation);
      return () => {
        window.removeEventListener("orientationchange", updateOrientation);
      };
    }, []);

    useEffect(() => {
      const max = Math.floor(containerWidth * 0.047);
      const str = parseMessage(post.message, max);
      setParsedMessage(<p dangerouslySetInnerHTML={{ __html: str }} />);
      if (containerWidth > 0) {
        sizePhotos();
      }
      // setParsedMessage(
      //   <p style={{ wordWrap: "break-word" }}>
      //     Check out zee link:{" "}
      //     <a
      //       href="https://www.npmjs.com/package/react-show-more-text"
      //       target="_blank"
      //       rel="noopener noreferrer"
      //       style={{ wordBreak: "break-all" }}
      //     >
      //       small link...
      //     </a>{" "}
      //     Here is a message without any breaks but also has a url then after the
      //     url there is a lot more text so that the message will be hopefully more
      //     than the max number of lines required to use the show more button but
      //     this is currently not enough so I will ramble on and on until I reach
      //     the number of lines required and I think I have it now. Actually I did't
      //     have it but now it should work.
      //     <br />
      //     <br />
      //     <img src="https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2021/12/30151747/Pembroke-Welsh-Corgi-smiling-and-happy-outdoors.jpeg"></img>
      //   </p>
      // );
    }, [containerWidth]);

    useEffect(() => {
      if (parsedMessage && loadMessageRef && loadMessageRef.current) {
        const divHeight = loadMessageRef.current.offsetHeight;
        // console.log(`divHeight: ${divHeight}`);
        const lineHeight = 24; //loadMessageRef.current.style.lineHeight;
        // console.log(`lineHeight: ${lineHeight}`);
        const lines = divHeight / lineHeight;
        // console.log(`lines: ${lines}`);
        // By default, we will not use the show more
        if (lines > maxLines) {
          // console.log(`THIS ONE IS TOO LONG`);
          const charsPerLine = setExpanded(false);
          setExpandByClick(true);
        }
        setMessageReady(true);
      }
    }, [parsedMessage, loadMessageRef]);

    /**
 Returns the dimensions of a video asynchrounsly.
 @param {String} url Url of the video to get dimensions from.
 @return {Promise<{width: number, height: number}>} Promise which returns the dimensions of the video in 'width' and 'height' properties.
 */
    function getVideoDimensionsOf(url) {
      return new Promise((resolve) => {
        // create the video element
        const video = document.createElement("video");

        // place a listener on it
        video.addEventListener(
          "loadedmetadata",
          function () {
            // retrieve dimensions
            const height = this.videoHeight;
            const width = this.videoWidth;

            // send back result
            resolve({ width: width, height: height });
          },
          false
        );

        // start download meta-datas
        video.src = url;
      });
    }

    const sizePhotos = () => {
      if (post.videos && post.videos.length > 0 && containerWidth > 0) {
        getVideoDimensionsOf(post.videos[0].url).then((result) => {
          console.log(`video h, w = ${result.height}, ${result.width}`);
          const obj = {
            url: post.videos[0].url,
            width: result.width,
            height: result.height,
          };
          const maxHeight = 500;
          if (obj.width < containerWidth) {
            // Fill container width, but only if height does not exceed max
            let change = containerWidth - obj.width;
            obj.width = obj.width + change;
            obj.height = obj.height + change;
            if (obj.height > maxHeight) {
              change = obj.height - maxHeight;
              obj.width = obj.width - change;
              obj.height = obj.height - change;
            }
          }
          setVideo(obj);
        });
      }
      if (post.images && post.images.length > 0 && containerWidth > 0) {
        // put images in proper order
        const tempArr = post.images.map((i) => {
          const image = new Image();
          image.src = i.url_small;
          i.tempImage = image;
          return i;
        });
        setTimeout(() => {
          const arr = tempArr.map((i) => {
            // console.log(`post_id: ${post.id} image_id: ${i.id}`);
            const image = i.tempImage;
            let width = image.width;
            let height = image.height;
            const ratio = width / height;
            // if (post.images.length == 1) {
            //   console.log(
            //     `containerWidth: ${containerWidth} width: ${width} height: ${height} ratio: ${ratio}`
            //   );

            //   const factor = containerWidth / width;
            //   width = width * factor;
            //   height = height * factor;

            //   setTileViewportStyle({
            //     maxHeight: "500px",
            //     maxWidth: `${containerWidth}px`,
            //     overflow: "hidden",
            //   });

            //   setRowHeight(height);
            // }

            return {
              id: i.id,
              src: i.url_small,
              original: i.url_large,
              width: width,
              height: height,
              customOverlay: (
                <div className="custom-overlay__caption">
                  <div>{image.caption}</div>
                  {image.tags &&
                    image.tags.map((t, index) => (
                      <div key={index} className="custom-overlay__tag">
                        {t.title}
                      </div>
                    ))}
                </div>
              ),
            };
          });

          setImages(arr);
        }, 200);
      }
    };
    return (
      <div ref={ref}>
        {post && token && (
          <>
            <div
              className="bg-white py-3 rounded my-4 position-relative"
              style={{ minHeight: "200px" }}
            >
              <button
                className="btn ml-3 position-absolute"
                style={{ top: "5px", right: "5px" }}
                type="button"
                onClick={() => openPostMenu(post)}
              >
                <i
                  className="fa fa-ellipsis-v text-medium-dark"
                  style={{ fontSize: "19px" }}
                ></i>
              </button>

              <div className="px-2">
                <div className="d-flex flex-wrap gap-1 mb-3">
                  {post.groups &&
                    post.groups.length > 0 &&
                    post.groups[0] &&
                    post.groups.map((group) => (
                      <Tag
                        key={`group-tag-${group.id}`}
                        title={group.name}
                        onClick={() => addPostedToGroup(group)}
                      />
                    ))}
                  {post.users &&
                    post.users.length > 0 &&
                    post.users[0] &&
                    post.users.map((u) => (
                      <Tag
                        key={`user-tag-${u.id}`}
                        title={`${u.first_name} ${u.last_name}`}
                        bold={u.id === user.id}
                        onClick={() => addPostedByUser(u)}
                      />
                    ))}
                </div>
                <div
                  className="d-flex align-items-center mb-2"
                  onClick={() => addPostedByUser(post.creator)}
                >
                  <Avatar user={post.creator} />
                  <div className="px-2" style={{ flexGrow: "1" }}>
                    <h6 className="text-dark mb-0">{`${post.creator.first_name} ${post.creator.last_name}`}</h6>
                    {post.creator.screen_name && (
                      <p className="small text-medium mb-1 mt-0">
                        {post.creator.screen_name}
                      </p>
                    )}
                  </div>
                  <p
                    className="small text-medium mb-1 mt-0"
                    style={{ maxWidth: "140px", textAlign: "right" }}
                  >
                    {orderTable == "updated_at" && (
                      <>
                        {post.what_updated}{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {timeDifference(
                            Date.now(),
                            Date.parse(post.updated_at)
                          )}
                        </span>
                      </>
                    )}
                    {orderTable == "created_at" && (
                      <>
                        {"Post created"}{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {timeDifference(
                            Date.now(),
                            Date.parse(post.created_at)
                          )}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
              {images && (
                <div>
                  {images.length == 1 && (
                    <div
                      onClick={() => {
                        setImageIndex(0);
                        setShowImageViewer(true);
                      }}
                      style={{ maxHeight: "500px", overflow: "hidden" }}
                    >
                      <img src={images[0].src} style={{ width: "100%" }} />
                    </div>
                  )}
                  {images.length > 1 && (
                    <Gallery
                      images={images}
                      rowHeight={rowHeight}
                      enableImageSelection={false}
                      onClick={(i, image, evt) => {
                        console.log(`image clicked ${i}`);
                        console.log(image);
                        setImageIndex(i);
                        setShowImageViewer(true);
                      }}
                    />
                  )}
                </div>
              )}
              {video && (
                <div
                  className="w-100 d-flex justify-content-center"
                  style={{
                    backgroundColor: "#000000",
                  }}
                >
                  <ReactPlayer
                    url={video.url}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                </div>
              )}
              <div className="px-2">
                <div className="mt-3" style={{ wordWrap: "break-word" }}>
                  {parsedMessage && !messageReady && (
                    <div ref={loadMessageRef} style={{ visibility: "hidden" }}>
                      {parsedMessage}
                    </div>
                  )}
                  {messageReady && (
                    <ShowMoreText
                      lines={maxLines}
                      more="Show more"
                      less={expandByClick ? "Show less" : ""}
                      className="text-dark show-more-text"
                      anchorClass="text-secondary h6"
                      expanded={expanded}
                      expandByClick={expandByClick}
                      // width={containerWidth}
                      keepNewLines={false}
                      truncatedEndingComponent={"..."}
                    >
                      {parsedMessage}
                    </ShowMoreText>
                  )}
                </div>
              </div>
              {post.poll && (
                <PollView
                  post={post}
                  user={user}
                  token={token}
                  setRefreshPostId={setRefreshPostId}
                />
              )}
              <ReactionsView
                post={post}
                user={user}
                token={token}
                setRefreshPostId={setRefreshPostId}
              />
              <CommentsView
                post={post}
                user={user}
                token={token}
                setRefreshPostId={setRefreshPostId}
                containerWidth={containerWidth}
                addPostedByUser={addPostedByUser}
                setShowFloatingButton={setShowFloatingButton}
              />
            </div>
          </>
        )}
        {images && showImageViewer && (
          <ImageViewer
            onClose={() => setShowImageViewer(false)}
            images={images}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
          />
        )}
      </div>
    );
  }
);
export default PostView;

{
  /* <textarea
                disabled
                className="bg-white border-0 w-100"
                style={{ disable: true, resize: "none" }}
              >
                {post.message}
              </textarea> */
}
