import React, { useEffect, useState } from "react";
import axios from "axios";
import Avatar from "../Avatar";
import Tag from "../Tag";
import Modal from "react-bootstrap/Modal";

function TagManager({
  token,
  groups,
  setGroups,
  users,
  setUsers,
  title = "Who can see this post?",
  disabled = false,
  bgStyle = { minHeight: "90px" },
  onSelect = (type, obj) => {},
}) {
  const [keywords, setKeywords] = useState("");
  const [suggestedUsers, setSuggestedUsers] = useState();
  const [suggestedGroups, setSuggestedGroups] = useState();
  const [showDirectory, setShowDirectory] = useState(false);
  const [directoryPage, setDirectoryPage] = useState(0);
  const [directoryGroupData, setDirectoryGroupData] = useState();
  const [directoryUsers, setDirectoryUsers] = useState();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && groups && groups.length == 0 && users.length == 0) {
      // const localEveryoneGroupId = localStorage.getItem("everyone-group-id");
      // if (localEveryoneGroupId) {
      //   const everyone = { name: "Everyone", id: localEveryoneGroupId };
      //   setGroups([everyone]);
      // } else {
      axios
        .get(`/api/v1/everyone_group`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          const everyone = { name: "Everyone", id: response.data.id };
          setGroups([everyone]);
          setInitialized(true);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
      // }
    }
  }, [groups, users]);

  useEffect(() => {
    if (!groups) {
      onSelectDirectoryPage("users");
    }
  }, [showDirectory]);

  let interval = {};
  useEffect(() => {
    clearInterval(interval);
    if (keywords.length >= 1) {
      const keywordsBeforeInterval = keywords;
      interval = setInterval(() => {
        clearInterval(interval);
        if (keywords === keywordsBeforeInterval) {
          performKeywordSearch();
        }
      }, 800);
    } else {
      // console.log(`not long enough: ${keywords}`);
    }
    return () => clearInterval(interval);
  }, [keywords]);

  const handleKeywordsChange = (e) => {
    setSuggestedUsers(null);
    setSuggestedGroups(null);
    setKeywords(e.target.value);
  };

  const performKeywordSearch = () => {
    const data = { keywords: keywords, not_self: true, respect_private: true };
    if (!groups) {
      data["users_only"] = true;
    }
    axios
      .get(`/api/v1/suggestions/`, {
        params: data,
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setSuggestedUsers(response.data.users);
        setSuggestedGroups(response.data.groups);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.error);
        console.log(error);
      });
  };

  const addUser = (user) => {
    const existing = users.filter((u) => u.id == user.id);
    if (existing.length == 0) {
      setUsers((prevItems) => [...prevItems, user]);
    }
    onSelect("user", user);
  };

  const removeUser = (user) => {
    setUsers((prevItems) => prevItems.filter((data) => data.id !== user.id));
  };

  const addGroup = (group) => {
    const existing = groups.filter((g) => g.id == group.id);

    if (existing.length == 0) {
      setGroups((prevItems) => [...prevItems, group]);
    }
    onSelect("group", group);
  };

  const removeGroup = (group) => {
    setGroups((prevItems) => prevItems.filter((data) => data.id !== group.id));
  };

  const onSelectDirectoryPage = (type) => {
    if (type == "groups") {
      setDirectoryPage(1);
      axios
        .get(`/api/v1/groups`, {
          params: { include_everyone: true },
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          console.log(response.data);
          setDirectoryGroupData(response.data);
        })
        .catch((error) => {
          // setErrorMessage(error.response.data.error);
          console.log(error);
        });
    } else if (type == "users") {
      setDirectoryPage(2);
      axios
        .get(`/api/v1/users`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          console.log(response.data);
          setDirectoryUsers(response.data);
        })
        .catch((error) => {
          // setErrorMessage(error.response.data.error);
          console.log(error);
        });
    }
  };

  const onSelectDirectoryGroup = (group) => {
    addGroup(group);
    setShowDirectory(false);
    setDirectoryPage(0);
  };

  const onSelectDirectoryUser = (user) => {
    addUser(user);
    setShowDirectory(false);
    setDirectoryPage(0);
  };
  return (
    <div className="mb-4">
      {(suggestedGroups || suggestedUsers) && (
        <div
          className="bg-light position-absolute"
          style={{
            opacity: "0.4",
            zIndex: "1",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
          }}
          onClick={() => {
            console.log("click");
            setSuggestedGroups(null);
            setSuggestedUsers(null);
            setKeywords("");
          }}
        />
      )}
      <label className="form-label text-medium-dark">{title}</label>

      <div className="d-flex w-100 mb-3 gap-2">
        <button
          type="button"
          className="btn btn-outline-primary"
          style={{ width: "44px" }}
          onClick={() => setShowDirectory(true)}
          disabled={disabled}
        >
          <i className="fa fa-book-open text-primary"></i>
        </button>

        <div className="input-group position-relative flex-grow-1">
          <input
            type="text"
            className="form-control"
            style={{ paddingLeft: "35px" }}
            placeholder={
              groups ? "Person or Group Name" : "First, Last or Email"
            }
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={keywords}
            onChange={handleKeywordsChange}
            disabled={disabled}
          ></input>
          <i
            className="fa fa-search text-medium position-absolute"
            style={{ top: "10px", left: "12px" }}
          ></i>
          {(suggestedUsers || suggestedGroups) && (
            <button
              className="btn position-absolute"
              style={{ top: "0", right: "0" }}
              id="basic-addon1"
              type="button"
              onClick={() => {
                setKeywords("");
                setSuggestedGroups(null);
                setSuggestedUsers(null);
              }}
            >
              <i className="fa fa-times text-medium-dark"></i>
            </button>
          )}

          {(suggestedUsers?.length > 0 || suggestedGroups?.length > 0) && (
            <>
              <div
                className="position-absolute bg-light shadow"
                style={{
                  maxHeight: "190px",
                  overflowY: "auto",
                  top: "40px",
                  left: "0",
                  right: "0",
                  zIndex: "3",
                }}
              >
                {suggestedGroups &&
                  suggestedGroups.length > 0 &&
                  suggestedGroups.map((group) => (
                    <button
                      key={`group-${group.id}`}
                      type="button"
                      className="bg-white p-2 list-group-item list-group-item-action d-flex gap-2 align-items-center"
                      style={{ marginBottom: "1px" }}
                      onClick={() => {
                        addGroup(group);
                        setSuggestedGroups(null);
                        setSuggestedUsers(null);
                        setKeywords("");
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "40px", height: "40px" }}
                      >
                        <i
                          className="fa fa-group"
                          style={{ fontSize: "22px" }}
                        ></i>
                      </div>
                      {` ${group.name}`}
                    </button>
                  ))}
                {suggestedUsers &&
                  suggestedUsers.length > 0 &&
                  suggestedUsers.map((user) => (
                    <button
                      key={`user-${user.id}`}
                      type="button"
                      className="bg-white p-2 list-group-item list-group-item-action d-flex gap-2 align-items-center"
                      style={{ marginBottom: "1px" }}
                      onClick={() => {
                        addUser(user);
                        setSuggestedGroups(null);
                        setSuggestedUsers(null);
                        setKeywords("");
                      }}
                    >
                      <Avatar user={user} />
                      <div>
                        <span className="d-block">{`${user.first_name} ${user.last_name}`}</span>
                        {user.screen_name !=
                          `${user.first_name} ${user.last_name}` && (
                          <span className="text-medium small">
                            {user.screen_name}
                          </span>
                        )}
                      </div>
                    </button>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="bg-white p-1 mb-3" style={bgStyle}>
        <div className="d-flex flex-wrap gap-1">
          {groups &&
            groups.length > 0 &&
            groups[0] &&
            groups.map((group) => (
              <Tag
                key={`group-${group.id}`}
                title={group.name}
                onClose={() => removeGroup(group)}
              />
            ))}
          {users.length > 0 &&
            users[0] &&
            users.map((user) => (
              <Tag
                key={`user-${user.id}`}
                title={`${user.first_name} ${user.last_name}`}
                onClose={() => removeUser(user)}
              />
            ))}
          {groups && groups.length == 0 && users.length == 0 && (
            <span className="p-2" style={{ color: "#212529bd" }}>
              Must have at least one group or person
            </span>
          )}
        </div>
      </div>
      <Modal
        show={showDirectory}
        onHide={() => {
          setShowDirectory(false);
          setDirectoryPage(0);
        }}
      >
        <Modal.Header
          closeButton
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#ffffff",
          }}
        >
          {directoryPage > 0 && groups && (
            <button
              className="btn"
              onClick={() => {
                setDirectoryPage(0);
              }}
            >
              <i className="fa fa-chevron-left text-primary"></i>
            </button>
          )}
          <span className="text-medium">
            {directoryPage == 0 && "Directory"}
            {directoryPage == 1 && "Groups"}
            {directoryPage == 2 && "Users"}
          </span>
        </Modal.Header>
        <Modal.Body>
          {directoryPage == 0 && (
            <DirectoryHome onSelect={(type) => onSelectDirectoryPage(type)} />
          )}
          {directoryPage == 1 && directoryGroupData && (
            <DirectoryPageGroups
              data={directoryGroupData}
              onSelect={(group) => onSelectDirectoryGroup(group)}
            />
          )}
          {directoryPage == 2 && directoryUsers && (
            <DirectoryPageUsers
              data={directoryUsers}
              onSelect={(user) => onSelectDirectoryUser(user)}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
const DirectoryHome = ({ onSelect }) => (
  <div className="d-flex align-items-center flex-column">
    <button
      className="bg-white w-100 d-flex justify-content-between align-items-center border-0 text-dark"
      style={{ height: "44px" }}
      onClick={() => onSelect("groups")}
    >
      Groups<i className="fa fa-chevron-right text-primary"></i>
    </button>
    <div className="border-bottom" style={{ width: "95%" }} />
    <button
      className="bg-white w-100 d-flex justify-content-between align-items-center border-0 text-dark"
      style={{ height: "44px" }}
      onClick={() => onSelect("users")}
    >
      Users<i className="fa fa-chevron-right text-primary"></i>
    </button>
  </div>
);

const DirectoryPageGroups = ({ data, onSelect }) => (
  <div className="d-flex align-items-center flex-column">
    <p className="w-100 text-medium">
      <small>MY GROUPS</small>
    </p>
    {data &&
      data.my_groups &&
      data.my_groups.length > 0 &&
      data.my_groups.map((g, i) => (
        <div className="w-100" key={`${g.name}-${i}`}>
          <button
            className="bg-white w-100 d-flex justify-content-between align-items-center border-0 text-dark"
            style={{ height: "44px" }}
            onClick={() => onSelect(g)}
          >
            {g.name}
          </button>
          {i < data.my_groups.length - 1 && (
            <div className="border-bottom" style={{ width: "95%" }} />
          )}
        </div>
      ))}
    <p className="mt-3 w-100 text-medium">
      <small>OTHER GROUPS</small>
    </p>
    {data &&
      data.groups &&
      data.groups.length > 0 &&
      data.groups.map((g, i) => (
        <div className="w-100" key={`${g.name}-${i}`}>
          <button
            className="bg-white w-100 d-flex justify-content-between align-items-center border-0 text-dark"
            style={{ height: "44px" }}
            onClick={() => onSelect(g)}
          >
            {g.name}
          </button>
          {i < data.groups.length - 1 && (
            <div className="border-bottom" style={{ width: "95%" }} />
          )}
        </div>
      ))}
  </div>
);

const DirectoryPageUsers = ({ data, onSelect }) => (
  <div className="d-flex align-items-center flex-column">
    {data &&
      data.length > 0 &&
      data.map((u, i) => (
        <div className="w-100" key={u.id}>
          <button
            className="bg-white w-100 d-flex gap-2 align-items-center border-0 text-dark"
            style={{ height: "48px" }}
            onClick={() => onSelect(u)}
          >
            <Avatar user={u} />
            {`${u.first_name} ${u.last_name}`}
          </button>
          {i < data.length - 1 && (
            <div className="border-bottom" style={{ width: "95%" }} />
          )}
        </div>
      ))}
  </div>
);
export default TagManager;
