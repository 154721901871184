import React from "react";

function Alert({ message, onClose, type = "success" }) {
  return (
    <div className={`alert alert-${type} d-flex m-0 w-100`} role="alert">
      <span className="flex-grow-1">{message}</span>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => onClose()}
      ></button>
    </div>
  );
}
export default Alert;
