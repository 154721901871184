import React, { useEffect, useState, useRef } from "react";
import { timeDifference } from "../../utils";
import ShowMoreText from "react-show-more-text";
import Avatar from "../Avatar";
import Form from "../Form";
import clsx from "clsx";
import axios from "axios";
import Tag from "../Tag";
import { parseMessage } from "../../utils";
import Modal from "react-bootstrap/Modal";
import ReactionsView from "./ReactionsView";
import { useSearchParams } from "react-router-dom";

function CommentsView({
  post,
  user,
  token,
  setRefreshPostId,
  containerWidth,
  addPostedByUser,
  setShowFloatingButton,
}) {
  const [isCommenting, setIsCommenting] = useState(false);
  const [message, setMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [editMessage, setEditMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [replyToComment, setReplyToComment] = useState();
  const [deletingComment, setDeletingComment] = useState();
  const [editingComment, setEditingComment] = useState();
  const maxLines = 7;

  const [searchParams, setSearchParams] = useSearchParams();
  const comment_id = searchParams.get("comment_id");
  const myRef = useRef(null);
  const [showAll, setShowAll] = useState(comment_id != null);

  // useEffect(() => {
  //   if(!showAll && post.comments.length > 5) {

  //   }
  // }, [])

  useEffect(() => {
    if (comment_id && myRef.current) {
      console.log("should scroll comment into view");
      setTimeout(() => {
        myRef.current.scrollIntoView();
      }, 500);
    }
  }, [comment_id, myRef]);

  const handleSaveComment = (event) => {
    event.preventDefault();
    let data = { post_id: post.id, message: message, created_by: user.id };
    if (replyToComment) {
      // This captures the root comment
      data.comment_id = replyToComment.comment_id
        ? replyToComment.comment_id
        : replyToComment.id;
      data.at_user_id = replyToComment.creator.id;
      data.message = replyMessage;
    }
    axios
      .post(`/api/v1/comments`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        if (!replyToComment && !comment_id) {
          // We should bring the new comment into view
          // it can be odd for a user to not see their comment they just saved
          console.log("should bring this new comment into view");
          console.log(response);
          // setCreatedCommentId(response.data.id);
          //TODO figure out how to bring the newly created comment into view
        }
        setMessage("");
        setReplyMessage("");
        setReplyToComment(null);
        setIsCommenting(false);
        // fetchComments();
        setRefreshPostId(post.id);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Could not save comment");
        }
      });
  };

  const handleUpdateComment = (event) => {
    event.preventDefault();
    let data = { comment_id: editingComment.id, message: editMessage };
    axios
      .put(`/api/v1/comments/${editingComment.id}`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setEditMessage("");
        setEditingComment(null);
        setRefreshPostId(post.id);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  // const fetchComments = () => {
  //   let data = { post_id: post.id };
  //   axios
  //     .get(`/api/v1/comments`, {
  //       params: data,
  //       headers: { Authorization: "Bearer " + token },
  //     })
  //     .then((response) => {
  //       console.log("received comments");
  //       console.log(response.data);
  //       setComments(response.data);
  //     })
  //     .catch((error) => {
  //       setErrorMessage(error.response.data.error);
  //     });
  // };

  const deleteComment = (c) => {
    const commentId = c.id;
    axios
      .delete(`/api/v1/comments/${commentId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        // console.log("deleted comment");
        // fetchComments();
        setRefreshPostId(post.id);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  const levelTwoData = (data, levelOneData) => {
    data.level_2 = true;
    return data;
  };

  const parsedCommentMessage = (c) => {
    const factor = c.level_2 ? 0.024 : 0.036;
    const max = Math.floor(containerWidth * factor);
    const str = parseMessage(c.message, max);
    return <p dangerouslySetInnerHTML={{ __html: str }} />;
  };

  const MaxCount = 5;
  let totalCount = 0;
  const commentView = (c, index) => {
    totalCount = totalCount + 1;
    if (totalCount > MaxCount && !showAll) {
      return <></>;
    }
    let comment_ref = null;
    if (comment_id && comment_id == c.id) {
      console.log("found the comment from url params");
      comment_ref = myRef;
    }
    // if (createdCommentId && createdCommentId == c.id) {
    //   console.log("found the comment just created");
    //   comment_ref = myRef;
    // }
    return (
      <div
        key={`comment-${c.id}`}
        className={clsx({
          "p-2 pb-0 border-light": true,
          "bg-white": !c.level_2,
          "bg-light-blue rounded mb-2": c.level_2,
          "bg-accent": comment_ref,
        })}
        ref={comment_ref}
      >
        <div
          className={clsx({
            "d-flex gap-2": true,
            "rounded bg-lighter pt-3 pb-1 px-2": !c.level_2,
            "pt-2": c.level_2,
            "bg-accent": comment_ref,
          })}
        >
          <div onClick={() => addPostedByUser(c.creator)}>
            <Avatar user={c.creator} />
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between flex-wrap">
              <div style={c.creator.screen_name ? { marginTop: "-4px" } : {}}>
                <span
                  className="text-dark mb-0"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: c.creator.screen_name ? "16px" : "",
                  }}
                >
                  {`${c.creator.first_name} ${c.creator.last_name}`}
                </span>
                {c.creator.screen_name && (
                  <p
                    className="text-medium mb-0"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    {c.creator.screen_name}
                  </p>
                )}
              </div>
              <span className="text-medium" style={{ fontSize: "12px" }}>
                {timeDifference(Date.now(), Date.parse(c.created_at))}
              </span>
            </div>
            <div
              className={clsx({
                "mb-0": !c.is_deleted,
                "mb-3": c.is_deleted,
                "mt-2": c.creator.screen_name,
                "text-medium": c.is_deleted,
              })}
            ></div>

            {c.at_user && (
              <div className="my-1">
                <Tag title={`${c.at_user.first_name} ${c.at_user.last_name}`} />
              </div>
            )}
            {c.is_deleted && "[Comment deleted by user]"}
            {!c.is_deleted && editingComment?.id != c.id && (
              <ShowMoreText
                lines={maxLines}
                more="Show more"
                less="Show less"
                className="text-dark"
                anchorClass="text-secondary h6"
                expanded={false}
                // width={containerWidth}
                keepNewLines={false}
                truncatedEndingComponent={"..."}
              >
                {parsedCommentMessage(c)}
              </ShowMoreText>
            )}
            {editingComment && editingComment.id == c.id && (
              <Form
                handleSubmit={handleUpdateComment}
                successMessage={successMessage}
                errorMessage={errorMessage}
                submitLabel={"Save"}
                className="position-relative mb-3"
                submitDisabled={editMessage.length == 0}
                onCancel={() => setEditingComment(null)}
              >
                <div className="my-3">
                  <textarea
                    style={{
                      minHeight: "210px",
                    }}
                    value={editMessage}
                    onChange={(e) => {
                      setEditMessage(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail3"
                    aria-describedby="message"
                    placeholder="Write a message..."
                    autoFocus
                  />
                </div>
              </Form>
            )}

            {replyToComment?.id != c.id && (
              <>
                {editingComment?.id != c.id && (
                  <div className="mb-3">
                    <div className="mb-2 d-flex gap-2 justify-content-end">
                      {!c.is_deleted && c.creator.id != user.id && (
                        <button
                          className="btn btn-small p-0 text-medium-dark"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            setReplyMessage("");
                            setReplyToComment(c);
                          }}
                        >
                          Reply
                        </button>
                      )}
                      {!c.is_deleted && c.creator.id == user.id && (
                        <button
                          className="btn btn-small btn-link text-medium-dark p-0"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            setEditingComment(c);
                            setEditMessage(c.message);
                          }}
                        >
                          Edit
                        </button>
                      )}
                      {!c.is_deleted && c.creator.id == user.id && (
                        <button
                          className="btn btn-small btn-link text-medium-dark p-0"
                          style={{ textDecoration: "none" }}
                          onClick={() => setDeletingComment(c)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                    <ReactionsView
                      comment={c}
                      user={user}
                      token={token}
                      setRefreshPostId={setRefreshPostId}
                    />
                  </div>
                )}

                {c.comments &&
                  c.comments.length > 0 &&
                  c.comments[0] &&
                  c.comments.map((sub_c, i) =>
                    commentView(levelTwoData(sub_c, c), i)
                  )}
              </>
            )}
          </div>
        </div>
        {replyToComment && replyToComment.id == c.id && (
          <Form
            handleSubmit={handleSaveComment}
            successMessage={successMessage}
            errorMessage={errorMessage}
            submitLabel={"Reply"}
            className="position-relative pb-2"
            submitDisabled={replyMessage.length == 0}
            onCancel={() => setReplyToComment(null)}
          >
            <div className="my-3">
              <textarea
                style={{ minHeight: "210px", paddingTop: "39px" }}
                value={replyMessage}
                onChange={(e) => {
                  setReplyMessage(e.target.value);
                }}
                type="text"
                className="form-control"
                id="exampleInputEmail2"
                aria-describedby="message"
                placeholder="Write a reply..."
                autoFocus
                onFocus={() => {
                  setShowFloatingButton(false);
                }}
                onBlur={() => {
                  setShowFloatingButton(true);
                }}
              />
            </div>
            <div
              className="position-absolute"
              style={{ top: "7px", left: "7px" }}
            >
              <Tag
                title={`${replyToComment.creator.first_name} ${replyToComment.creator.last_name}`}
              />
            </div>
          </Form>
        )}
      </div>
    );
  };

  return (
    <>
      {post && user && (
        <div className="mt-4">
          <h6 className="px-2 text-medium-dark small">{`Comments ${
            "(" + post.comments.length + ")"
          }`}</h6>
          <div
            className={clsx({
              "px-1": true,
              "py-2": !isCommenting,
              "py-3 bg-light border border-medium-dark": isCommenting,
            })}
          >
            {isCommenting && (
              <Form
                handleSubmit={handleSaveComment}
                successMessage={successMessage}
                errorMessage={errorMessage}
                submitLabel={"Comment"}
                submitDisabled={message.length == 0}
                onCancel={() => setIsCommenting(false)}
              >
                <div className="mb-3">
                  <textarea
                    style={{ minHeight: "210px" }}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="message"
                    placeholder="Add a comment..."
                    autoFocus
                    onFocus={() => {
                      setShowFloatingButton(false);
                    }}
                    onBlur={() => {
                      setShowFloatingButton(true);
                    }}
                  />
                </div>
              </Form>
            )}
            {!isCommenting && (
              <input
                className="w-100 p-1 rounded border border-medium"
                placeholder="Add a comment..."
                onFocus={() => setIsCommenting(true)}
              />
            )}
          </div>
          {post.top_level_comments &&
            post.top_level_comments.length > 0 &&
            post.top_level_comments[0] && (
              <>
                {post.top_level_comments.map((c, index) => (
                  <div key={`post-${post.id}-comment-${c.id}`}>
                    {commentView(c, index)}
                  </div>
                ))}
                {!showAll && post.comments.length > 5 && (
                  <div className="mt-2 w-100 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setShowAll(true)}
                    >
                      {`Show ${post.comments.length - MaxCount} More Comments`}
                    </button>
                  </div>
                )}
              </>
            )}
        </div>
      )}
      <Modal show={deletingComment} onHide={() => setDeletingComment(null)}>
        <Modal.Body>{`Delete your comment?`}</Modal.Body>
        <Modal.Footer className="d-flex gap-2">
          <button
            className="btn"
            variant="secondary"
            onClick={() => setDeletingComment(null)}
          >
            No
          </button>
          <button
            className="btn btn-primary"
            variant="primary"
            onClick={() => {
              deleteComment(deletingComment);
              setDeletingComment(null);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommentsView;
