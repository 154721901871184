import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactImagePickerEditor from "react-image-picker-editor";
import Alert from "../Alert";
import { uploadImage } from "../../awsUtils";

function EditAvatar(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [imageSrc, setImageSrc] = useState();
  const [saveLabel, setSaveLabel] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const size = "250px"; // width and height

  const config1 = {
    borderRadius: "50%",
    language: "en",
    width: size,
    height: size,
    objectFit: "contain",
    compressInitial: 90,
    hideDeleteBtn: props.user.image,
    hideAddBtn: true,
    hideDownloadBtn: !props.user.image,
  };

  const updateDatabase = (uuid) => {
    setInfoMessage("Saving to database");
    const data = { uuid: uuid };
    axios
      .post(`/api/v1/images`, data, {
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((response) => {
        setInfoMessage("");
        setSuccessMessage("Your image has been saved");
        props.loadUser();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          setInfoMessage("");
          setErrorMessage("Your image could not be uploaded. [EA-P-1]");
          // setErrorMessage(error.response.data.error);
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage("Your image could not be uploaded. [EA-P-2]]");
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an Error
          setInfoMessage("");
          // setErrorMessage(error.message);
          setErrorMessage("Your image could not be uploaded. [EA-P-3]]");
        }
      });
  };

  const handleUpdateImage = async () => {
    try {
      setSaveLabel("Saving...");
      setSubmitDisabled(true);
      setInfoMessage("Uploading...");
      setErrorMessage("");
      setSuccessMessage("");
      await uploadImage(imageSrc, props.user.image.uuid).then((result) => {
        if (result && result.success) {
          setInfoMessage("");
          setSuccessMessage("Your image has been saved");
        } else {
          setInfoMessage("");
          setErrorMessage("Your image could not be uploaded");
        }
        setSaveLabel(null);
        setSubmitDisabled(false);
      });
    } catch (err) {
      console.log(err);
      setInfoMessage("");
      setErrorMessage("Could not save image");
      setSaveLabel(null);
      setSubmitDisabled(false);
    }
  };
  const handleSaveImage = async () => {
    try {
      setSaveLabel("Saving...");
      setSubmitDisabled(true);
      setInfoMessage("Uploading...");
      setErrorMessage("");
      setSuccessMessage("");
      await uploadImage(imageSrc).then((result) => {
        console.log("hat");
        console.log(result);
        if (result && result.success) {
          updateDatabase(result.uuid);
        } else {
          setInfoMessage("");
          setErrorMessage("Your image could not be uploaded");
        }
        setSaveLabel(null);
        setSubmitDisabled(false);
      });
    } catch (err) {
      console.log(err);
      setInfoMessage("");
      setErrorMessage("Could not save image");
      setSaveLabel(null);
      setSubmitDisabled(false);
    }
  };

  const handleDeleteImage = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setInfoMessage("");
    axios
      .delete(`/api/v1/images/${props.user.image_id}`, {
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((response) => {
        props.loadUser();
        // setSuccessMessage();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          setErrorMessage("Error deleting image");
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage("Error deleting image");
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an Error
          setErrorMessage("Error deleting image");
        }
      });
  };

  return (
    <>
      {infoMessage && !errorMessage && (
        <Alert
          message={infoMessage}
          onClose={() => setInfoMessage(null)}
          type="info"
        />
      )}
      {successMessage && (
        <Alert
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          type="success"
        />
      )}
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      <div className="bg-light p-3 rounded my-4">
        <label className="form-label text-dark">Avatar</label>
        <div
          id="edit-avatar-container"
          className="d-flex justify-content-center rounded-circle"
        >
          <ReactImagePickerEditor
            config={config1}
            imageSrcProp={props.user.image ? props.user.image.url_large : ""}
            imageChanged={(newDataUri) => {
              setImageSrc(newDataUri);
            }}
          />
        </div>
        {!props.user.image && (
          <button
            disabled={!imageSrc || submitDisabled}
            type="button"
            className="btn btn-primary mt-4"
            onClick={() => handleSaveImage()}
          >
            {saveLabel ? saveLabel : "Save Image"}
          </button>
        )}
        {props.user.image && (
          <div className="d-flex gap-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleUpdateImage()}
              disabled={submitDisabled}
            >
              {saveLabel ? saveLabel : "Save Changes"}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleDeleteImage()}
            >
              Delete Image
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default EditAvatar;
