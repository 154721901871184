import React, { useEffect, useState, useRef, createRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Navbar, { SortType } from "./Navbar";
import useAuthorization from "../hooks/useAuthorization";
import axios from "axios";
import PostView from "./PostView";
import PostSideMenu from "./post/PostSideMenu";
import Alert from "./Alert";
import Tag from "./Tag";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SearchType } from "./Navbar";
import UserStats from "./UserStats";
import GroupStats from "./GroupStats";
import Modal from "react-bootstrap/Modal";

function Home(props) {
  const user = useAuthorization();
  const [token, setToken] = useState();
  const [posts, setPosts] = useState([]);
  const [showPostMenu, setShowPostMenu] = useState(false);
  const [postToEdit, setPostToEdit] = useState();
  const containerRef = useRef(null);
  const postsScroller = useRef(null);
  const [loading, setLoading] = useState(true);
  const [olderThanDate, setOlderThanDate] = useState(null); // Get posts older than this
  const limit = 10;
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [postedToGroups, setPostedToGroups] = useState([]);
  const [postedToUsers, setPostedToUsers] = useState([]);
  const [postedByUsers, setPostedByUsers] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [postIds, setPostIds] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchType, setSearchType] = useState(SearchType.Keywords);
  const [refreshPostId, setRefreshPostId] = useState();
  const [userData, setUserData] = useState();
  const [groupData, setGroupData] = useState();
  const [showFloatingButton, setShowFloatingButton] = useState(true);
  const [showBackToTopBtn, setShowBackToTopBtn] = useState(false);
  const [previousLoadTime, setPreviousLoadTime] = useState(null);
  const [count, setCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const pin = searchParams.get("pin");
  const [showSetPasswordModal, setShowSetPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [orderTable, setOrderTable] = useState(null);

  const updateOrientation = (event) => {
    setCount(count + 1); // testing this to see if it re-renders properly
  };
  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  useEffect(() => {
    if (loading || refreshPostId) {
      let t = token;
      if (!token) {
        const localToken = localStorage.getItem("token");
        setToken(localToken);
        t = localToken;
      }
      let order_by = "created_at";
      // if (!orderTable) {
      const currentValue = localStorage.getItem("sort_posts");
      if (currentValue && currentValue != "") {
        const val =
          currentValue == SortType.Most_Recent_Activity
            ? "updated_at"
            : "created_at";
        setOrderTable(val);
        order_by = val;
      } else {
        setOrderTable("created_at");
      }
      // }

      const data = {
        limit: limit,
        older_than_date: olderThanDate,
        order_table: order_by,
      };
      if (postedToGroups.length > 0) {
        const groupIds = postedToGroups.map((g) => g.id);
        data.posted_to_groups = JSON.stringify(groupIds);
      }
      if (postedToUsers.length > 0) {
        const userIds = postedToUsers.map((u) => u.id);
        data.posted_to_users = JSON.stringify(userIds);
      }
      if (postedByUsers.length > 0) {
        const userIds = postedByUsers.map((u) => u.id);
        data.posted_by_users = JSON.stringify(userIds);
      } else {
        setUserData(null);
      }
      if (keywords.length > 0) {
        data.keywords = JSON.stringify(keywords);
      }
      const post_id = searchParams.get("post_id");
      if (post_id) {
        data.post_ids = JSON.stringify([post_id]);
        setPostIds([post_id]);
      }
      if (refreshPostId) {
        data.post_ids = JSON.stringify([refreshPostId]);
        data.older_than_date = null;
      }
      setPreviousLoadTime(Date.now());
      axios
        .get(`/api/v1/posts/`, {
          params: data,
          headers: { Authorization: `Bearer ${t ? t : token}` },
        })
        .then((response) => {
          if (response.data.user_data) {
            setUserData(response.data.user_data);
          }
          if (response.data.group_data) {
            setGroupData(response.data.group_data);
            console.log(response.data.group_data);
          }
          setAlertCount(response.data.alert_count);
          if (refreshPostId) {
            // Only update the post
            setPosts((prevItems) => {
              return prevItems.map((p) => {
                if (p.id == refreshPostId) {
                  return response.data.posts[0];
                } else {
                  return p;
                }
              });
              // [...prevItems, ...response.data]
            });
            setRefreshPostId(null);
          } else {
            console.log(response.data.posts);
            if (response.data.posts.length > 0) {
              setPosts((prevItems) => [...prevItems, ...response.data.posts]);
              const oldestDate =
                response.data.posts[response.data.posts.length - 1][order_by];
              // const newestDate = response.data[0].updated_at;
              setOlderThanDate(oldestDate); // Oldest post received so far
            }
          }

          setLoading(false);

          if (pin) {
            // User has just clicked Activate button in their invite email
            // It took them to login with a pin param
            // Login verified the pin and authenticated and navigated here
            // We must ask user to set their own password
            setShowSetPasswordModal(true);
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    } else {
      window.addEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, refreshPostId]);

  useEffect(() => {
    if (
      postedToGroups.length == 0 &&
      postedToUsers.length == 0 &&
      postedByUsers.length == 0 &&
      keywords.length == 0
    ) {
      setActiveSearch(false);
    } else {
      // return to top of scroll
      console.log("moving to top of scroll");
      postsScroller?.current.scrollTo(0, 0);
    }
  }, [keywords, postedToGroups, postedToUsers, postedByUsers]);

  const handleScroll = (e) => {
    const post_id = searchParams.get("post_id");

    const { scrollTop, clientHeight, scrollHeight } = e.target; //document.documentElement;
    if (!post_id && scrollTop + clientHeight >= scrollHeight - 25) {
      // User has scrolled close to bottom of list
      setLoading(true);
    }
    if (scrollTop > 2300) {
      // User has scrolled about 4-5 posts down
      setShowBackToTopBtn(true);
    }
    if (!scrollTop || scrollTop == 0) {
      // User has scrolled back up to top
      setShowBackToTopBtn(false);
      if (!loading && previousLoadTime) {
        const ellapsed = Date.now() - previousLoadTime;
        // console.log(`ellapsed: ${ellapsed}`);
        if (previousLoadTime && ellapsed > 15000) {
          // It has been over 15 seconds since last search
          // clearAndSearch();
          // TODO: this causes issues like when a user was in the middle of commenting
          // Need to figure this out better
        }
      }
    }
  };

  const savePassword = () => {
    let data = {
      user_id: user.id,
      password: password,
      password_confirm: password,
    };
    axios
      .put(`/api/v1/users/${user.id}/password`, data, {
        withCredentials: true,
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setPassword("");
        setShowSetPasswordModal(false);
        searchParams.delete("pin");
        setSearchParams(searchParams);
      })
      .catch((error) => {
        setPasswordErrorMessage(error.response.data.error);
      });
  };

  const openPostMenu = (post) => {
    setPostToEdit(post);
    setShowPostMenu(true);
  };

  const closePostMenu = (refreshPosts = false, alertObject = null) => {
    setShowPostMenu(false);
    if (refreshPosts) {
      clearAndSearch();
    }
    if (alertObject) {
      if (alertObject.success) {
        setSuccessMessage(alertObject.message);
      } else if (alertObject.error) {
        setErrorMessage(alertObject.message);
      }
    }
  };
  const addPostedToGroup = (group) => {
    if (group.name != "Everyone") {
      const existing = postedToGroups.filter((g) => g.id == group.id);
      if (existing.length == 0) {
        // Originally I allowed searching by more than one group
        // setPostedToGroups((prevItems) => [...prevItems, group]);
        setPostedToGroups([group]);
        setPostedByUsers([]);
        setKeywords([]);
        clearAndSearch();
        setSearchType(SearchType.Keywords);
      }
    }
  };
  const removePostedToGroup = (group) => {
    setPostedToGroups((prevItems) =>
      prevItems.filter((data) => data.id !== group.id)
    );
    clearAndSearch();
  };

  const addPostedToUser = (user) => {
    const existing = postedToUsers.filter((u) => u.id == user.id);
    if (existing.length == 0) {
      setPostedToUsers((prevItems) => [...prevItems, user]);
      clearAndSearch();
    }
  };
  const removePostedToUser = (user) => {
    setPostedToUsers((prevItems) =>
      prevItems.filter((data) => data.id !== user.id)
    );
    clearAndSearch();
  };

  const addPostedByUser = (user) => {
    const existing = postedByUsers.filter((u) => u.id == user.id);
    if (existing.length == 0) {
      // Originally, I allowed searching by multiple users
      // setPostedByUsers((prevItems) => [...prevItems, user]);
      setPostedByUsers([user]);
      setPostedToGroups([]);
      setKeywords([]);
      clearAndSearch();
      setSearchType(SearchType.Keywords);
    }
  };
  const removePostedByUser = (user) => {
    setPostedByUsers((prevItems) =>
      prevItems.filter((data) => data.id !== user.id)
    );
    clearAndSearch();
  };

  const resetFilters = () => {
    setPostedToGroups([]);
    setPostedToUsers([]);
    setPostedByUsers([]);
    setKeywords([]);
    setPostIds([]);
    searchParams.delete("post_id");
    searchParams.delete("comment_id");
    setSearchParams(searchParams);
    setActiveSearch(false);
    setSearchType(SearchType.Keywords);
  };
  const clearAndSearch = () => {
    setOlderThanDate(null);
    setPosts([]);
    setLoading(true);
  };

  const addKeywords = (words) => {
    const existing = keywords.filter((w) => w == words);
    if (existing.length == 0) {
      setKeywords((prevItems) => [...prevItems, words]);
      clearAndSearch();
    }
  };

  const removeKeywords = (words) => {
    setKeywords((prevItems) => prevItems.filter((w) => w !== words));
    clearAndSearch();
  };

  return (
    <div className="bg-light">
      {user && token && (
        <>
          {postedByUsers.length > 0 && (
            <Navbar
              onBack={() => {
                setPostedByUsers([]);
                clearAndSearch();
              }}
              title="User"
            />
          )}
          {postedToGroups.length > 0 && (
            <Navbar
              onBack={() => {
                setPostedToGroups([]);
                clearAndSearch();
              }}
              title="Group"
            />
          )}
          {postedByUsers.length == 0 && postedToGroups.length == 0 && (
            <Navbar
              user={user}
              token={token}
              addKeywords={addKeywords}
              activeSearch={activeSearch}
              setActiveSearch={setActiveSearch}
              addPostedToGroup={addPostedToGroup}
              addPostedToUser={addPostedToUser}
              addPostedByUser={addPostedByUser}
              setSearchType={setSearchType}
              searchType={searchType}
              alertCount={alertCount}
              onSortChange={(type) => {
                switch (type) {
                  case SortType.Newest_Posts:
                    setOrderTable("created_at");
                    break;
                  case SortType.Most_Recent_Activity:
                    setOrderTable("updated_at");
                    break;
                  default:
                    setOrderTable("created_at");
                }
                clearAndSearch();
              }}
            />
          )}
          {successMessage && (
            <Alert
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
              type="success"
            />
          )}
          {errorMessage && (
            <Alert
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
              type="danger"
            />
          )}

          <div
            ref={postsScroller}
            className="row d-flex justify-content-center vh-100 bg-light pb-5"
            style={{ overflowY: "auto", overflowX: "hidden" }}
            onScroll={handleScroll}
          >
            {postedByUsers.length > 0 && userData && (
              <div className="my-3 d-flex align-items-center flex-column">
                <UserStats user={postedByUsers[0]} userData={userData} />
              </div>
            )}
            {postedToGroups.length > 0 && groupData && (
              <div className="col-12 col-md-10 col-lg-7 my-3 d-flex align-items-center flex-column">
                <GroupStats group={postedToGroups[0]} groupData={groupData} />
                <Link
                  className="btn btn-primary"
                  to={`/groups/${postedToGroups[0].id}`}
                >
                  More Details
                </Link>
              </div>
            )}
            <div ref={containerRef} className="col-12 col-md-10 col-lg-7">
              {(postedToUsers.length > 0 ||
                keywords.length > 0 ||
                postIds.length > 0) && (
                <div className="d-flex align-items-center gap-2 my-2">
                  <h6 className="text-dark m-0">
                    Results are filtered by the following:
                  </h6>
                  <button
                    type="button"
                    className="btn btn-outline-primary py-0"
                    style={{ width: "100px" }}
                    onClick={() => {
                      resetFilters();
                      clearAndSearch();
                    }}
                  >
                    Clear All
                  </button>
                </div>
              )}
              {postIds.length > 0 && (
                <div>
                  <p className="text-medium-dark mt-3 small m-0">
                    A specific post or group of posts
                  </p>
                </div>
              )}
              {/* {postedToGroups.length > 0 && (
                <div>
                  <p className="text-medium-dark mt-3 small m-0">
                    Addressed to Groups
                  </p>
                  <div className="d-flex flex-wrap gap-1 bg-white p-2">
                    {postedToGroups.map((group) => (
                      <Tag
                        key={`filter-group-${group.id}`}
                        title={group.name}
                        onClose={() => removePostedToGroup(group)}
                      />
                    ))}
                  </div>
                </div>
              )} */}
              {postedToUsers.length > 0 && (
                <div>
                  <p className="text-medium-dark mt-3 small m-0">
                    Addressed to Users
                  </p>
                  <div className="d-flex flex-wrap gap-1 bg-white p-2">
                    {postedToUsers.map((user) => (
                      <Tag
                        key={`filter-group-${user.id}`}
                        title={`${user.first_name} ${user.last_name}`}
                        onClose={() => removePostedToUser(user)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {/* {postedByUsers.length > 0 && (
                <div>
                  <p className="text-medium-dark mt-3 small m-0">
                    Created by Users
                  </p>
                  <div className="d-flex flex-wrap gap-1 bg-white p-2">
                    {postedByUsers.map((user) => (
                      <Tag
                        key={`filter-user-${user.id}`}
                        title={`${user.first_name} ${user.last_name}`}
                        onClose={() => removePostedByUser(user)}
                      />
                    ))}
                  </div>
                </div>
              )} */}
              {keywords.length > 0 && (
                <div>
                  <p className="text-medium-dark mt-3 small m-0">
                    Message Contains
                  </p>
                  <div className="d-flex flex-wrap gap-1 bg-white p-2">
                    {keywords.map((words) => (
                      <Tag
                        key={`filter-keywords-${words.trim()}`}
                        title={words}
                        onClose={() => removeKeywords(words)}
                        noAt={true}
                      />
                    ))}
                  </div>
                </div>
              )}

              {!loading && posts.length == 0 && (
                <>
                  {groupData && !groupData.is_member && (
                    <>
                      <h5 className="mt-3 text-center">
                        You are not a member of this group
                      </h5>
                    </>
                  )}
                  {!groupData && (
                    <h5 className="mt-3 text-center">No posts yet</h5>
                  )}
                </>
              )}

              {showBackToTopBtn && (
                <div
                  className="position-absolute d-flex justify-content-center"
                  style={{
                    top: "57px",
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    opacity: "0.4",
                  }}
                >
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setShowBackToTopBtn(false);
                      postsScroller?.current.scrollTo(0, 0);
                      clearAndSearch();
                    }}
                  >
                    Back to Top
                  </button>
                </div>
              )}

              <TransitionGroup>
                {containerRef?.current &&
                  posts.map((post, i) => {
                    const itemRef = createRef(null);
                    return (
                      <CSSTransition
                        nodeRef={itemRef}
                        key={i}
                        classNames="posts"
                        timeout={{ enter: 300, exit: 300 }}
                      >
                        <PostView
                          ref={itemRef}
                          key={post.id}
                          post={post}
                          user={user}
                          token={token}
                          openPostMenu={openPostMenu}
                          containerWidth={containerRef.current.offsetWidth}
                          addPostedToGroup={addPostedToGroup}
                          addPostedToUser={(u) => addPostedToUser(u)}
                          addPostedByUser={(u) => addPostedByUser(u)}
                          setRefreshPostId={setRefreshPostId}
                          setShowFloatingButton={setShowFloatingButton}
                          orderTable={orderTable}
                        />
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </div>
            {showFloatingButton && (
              <>
                {/* <Link
                  className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center position-absolute"
                  style={{
                    width: "55px",
                    height: "55px",
                    bottom: "12px",
                    right: "12px",
                  }}
                  to="/posts"
                >
                  <i className="fa fa-plus" style={{ fontSize: "25px" }}></i>
                </Link> */}
              </>
            )}
          </div>
          <PostSideMenu
            token={token}
            user={user}
            post={postToEdit}
            showPostMenu={showPostMenu}
            closePostMenu={closePostMenu}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        </>
      )}
      <Modal
        show={showSetPasswordModal}
        onHide={() => setShowSetPasswordModal(false)}
        backdrop="static"
      >
        <Modal.Header>Welcome to BlueChalupa!</Modal.Header>
        <Modal.Body>
          <p className="text-medium-dark">
            In the future when you login. You will use your email address and
            the password you create now. Your email is:
          </p>
          <p className="text-dark">{user?.email}</p>
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="text"
            placeholder="password"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="password"
            maxLength={50}
          />
          <button
            className="mt-3 btn btn-primary"
            onClick={() => savePassword()}
          >
            Save
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Home;
