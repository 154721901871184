import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import Form from "./Form";
import axios from "axios";
import TagManager from "./post/TagManager";
import { uploadImage, uploadVideo } from "../awsUtils";
import Alert from "./Alert";
import ReactPlayer from "react-player";
import PostFormImage from "./post/PostFormImage";

function PostForm() {
  const routeParams = useParams();
  const [id, setId] = useState();
  const [redirectTo, setRedirectTo] = useState("");
  const user = useAuthorization();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [videoErrorMessage, setVideoErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [postToEdit, setPostToEdit] = useState();
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitLabel, setSubmitLabel] = useState("Send Post");
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([
    { url: "", initialUrl: "", position: 0, hasChanged: false },
  ]);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoExtension, setVideoExtension] = useState("");
  const [videoData, setVideoData] = useState(null);
  const [poll, setPoll] = useState(null);
  const [enableVideo, setEnableVideo] = useState(true);
  const MAX_IMAGES = 6;
  const size = "120px";

  const config1 = {
    border: "2px solid",
    borderRadius: "5%",
    borderColor: "rgb(0,0,0,.3)",
    language: "en",
    width: size,
    height: size,
    objectFit: "contain",
    compressInitial: null,
    hideDeleteBtn: false,
    hideAddBtn: true,
    hideDownloadBtn: true,
    hideEditBtn: false,
  };

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken);
    if (routeParams && routeParams["id"]) {
      setId(routeParams["id"]);
    }
  }, []);

  useEffect(() => {
    const postHasAudience = groups.length > 0 || users.length > 0;
    const postHasMessage = message.trim().length > 0;
    setSubmitDisabled(!(postHasAudience && postHasMessage));
  }, [groups, users, message]);

  useEffect(() => {
    if (id && user) {
      setSubmitLabel("Update Post");
      const t = localStorage.getItem("token");
      const data = { post_ids: JSON.stringify([id]), signed: true };
      axios
        .get(`/api/v1/posts`, {
          params: data,
          headers: { Authorization: "Bearer " + t },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.posts && response.data.posts.length > 0) {
            const post = response.data.posts[0];

            if (post.created_by == user.id) {
              setPostToEdit(post);
              setMessage(post.message);
              setGroups(post.groups);
              setUsers(post.users);

              if (post.images && post.images.length > 0) {
                //   setImageUrl(post.images[0].url_large);
                const images = post.images.map((image, i) => ({
                  initialUrl: image.url_large,
                  url: image.url_large,
                  position: i,
                  hasChanged: false,
                  id: image.id,
                  uuid: image.uuid,
                }));
                setImages(images);
                setEnableVideo(false);
              }
              // if (post.images && post.images.length > 1) {
              //   setImageUrl(post.images[1].url_large);
              // }
              if (post.videos && post.videos.length == 1) {
                setVideoUrl(post.videos[0].url);
                setVideoExtension(post.videos[0].extension);
              }
              setLoading(false);
            } else {
              //   // This is a post, but it does not belong to this user!
              setErrorMessage("Could not load post [UE1]");
              setRedirectTo("/");
            }
          } else {
            setErrorMessage("Could not load post [UE2]");
          }
        })
        .catch((error) => {
          setErrorMessage("Could not load post [UE3]");
          console.log(error);
        });
    } else if (user) {
      setLoading(false);
    }
  }, [id, user]);

  const saveNewPostToDatabase = (image_uuids = [], video_uuid = "") => {
    let data = { message: message, created_by: user.id };
    data.group_ids = groups.map((g) => g.id).join(",");
    data.user_ids = users.map((u) => u.id).join(",");
    data.image_uuids = image_uuids.join(",");
    data.video_uuid = video_uuid;
    data.video_extension = videoExtension;
    if (poll) {
      data.poll = JSON.stringify(poll);
    }
    axios
      .post(`/api/v1/posts`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setRedirectTo("/");
      })
      .catch((error) => {
        setErrorMessage("Could not create post");
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    setSubmitLabel("Saving...");
    if (postToEdit) {
      updatePost();
    } else {
      const changedImages = images.filter((image) => image.hasChanged);
      if (changedImages && changedImages.length > 0) {
        const imageUuids = [];
        let promiseChain = Promise.resolve();
        for (let i = 0; i < changedImages.length; i++) {
          promiseChain = promiseChain.then(() => {
            uploadImage(changedImages[i].url).then((result) => {
              imageUuids.push(result.uuid);
              if (imageUuids.length == changedImages.length) {
                // This was the last upload
                saveNewPostToDatabase(imageUuids);
              }
            });
          });
        }
      } else if (videoData) {
        await uploadVideo(videoData, videoExtension).then((result) => {
          if (result && result.success) {
            saveNewPostToDatabase([], result.uuid);
          } else {
            setInfoMessage("");
            setErrorMessage("Your image could not be uploaded");
          }
        });
      } else {
        // No images and No video
        console.log("No images and no video to upload");
        saveNewPostToDatabase();
      }
    }
  };
  const saveUpdatedPostToDatabase = (data) => {
    axios
      .put(`/api/v1/posts/${postToEdit.id}`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        setRedirectTo("/");
        // setSuccessMessage(response.data.message);
        // props.loadUser();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  const updatePost = async () => {
    let data = { message: message, created_by: user.id };
    data.group_ids = groups.map((g) => g.id).join(",");
    data.user_ids = users.map((u) => u.id).join(",");
    data.image_uuids = "";
    data.video_uuid = "";

    if (videoData) {
      // Video was added
      data.video_changed = true;
    } else if (
      postToEdit.videos &&
      postToEdit.videos.length > 0 &&
      (!videoUrl || videoUrl == "")
    ) {
      // Video was removed
      data.video_changed = true;
    }
    // let imageAdded = false;
    const changedImages = images.filter((image) => image.hasChanged);

    const keepers = images.filter(
      (image) => image.uuid && image.url && !image.hasChanged
    );
    const uuids = keepers.map((image) => image.uuid);

    if (changedImages.length > 0 || uuids.length != images.length) {
      // at least one image has been added or removed
      data.images_changed = true;
    }
    data.image_uuids = uuids.join(",");

    if (changedImages.length > 0) {
      // If there was an image to beign with, there will already be a uuid
      // If this post didn't have an image, there will not be a uuid yet
      const uuidsUploaded = [];
      let promiseChain = Promise.resolve();
      for (let i = 0; i < changedImages.length; i++) {
        promiseChain = promiseChain.then(() => {
          uploadImage(changedImages[i].url).then((result) => {
            uuids.push(result.uuid);
            uuidsUploaded.push(result.uuid);
            if (uuidsUploaded.length == changedImages.length) {
              // This was the last upload
              console.log(`Finished the last one: ${i}`);
              data.image_uuids = uuids.join(",");
              saveUpdatedPostToDatabase(data);
            }
          });
        });
      }
    } else if (videoData && videoExtension) {
      // If there was a video to beign with, there will already be a uuid
      // If this post didn't have a video, there will not be a uuid yet
      const uuid =
        postToEdit.videos?.length > 0 ? postToEdit.videos[0].uuid : null;

      await uploadVideo(videoData, videoExtension, uuid).then((result) => {
        if (result && result.success) {
          data.video_uuid = result.uuid;
          data.video_extension = videoExtension;
          data.video_changed = true;
          saveUpdatedPostToDatabase(data);
        } else {
          setInfoMessage("");
          setErrorMessage("Your image could not be uploaded");
        }
      });
    } else {
      saveUpdatedPostToDatabase(data);
    }
  };

  const onVideoSelect = (file) => {
    const MAX_UPLOAD_BYTES = process.env.MAX_UPLOAD_BYTES;
    if (file.size > MAX_UPLOAD_BYTES) {
      const bytesPerMB = 1000000; // decimal
      // const bytesPerMB = 1048576; // binary
      const mb = MAX_UPLOAD_BYTES / bytesPerMB;
      setVideoErrorMessage(`File must be below ${mb} MB`);
    } else {
      const url = URL.createObjectURL(file);
      setVideoUrl(url);
      setVideoData(file);
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      setVideoExtension(ext);
    }
  };

  const addMultipleImages = (files, loaded = []) => {
    console.log(`Adding file from multiple. Total files: ${files.length}`);
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          const dataUri = reader.result;

          const newImage = {
            initialUrl: dataUri,
            url: dataUri,
            position: null,
            hasChanged: true,
            uuid: null,
          };

          let restOfFiles = [];
          for (var i = 0; i < files.length; i++) {
            if (i != 0) {
              restOfFiles.push(files[i]);
            }
          }
          loaded.push(newImage);

          if (restOfFiles.length > 0) {
            console.log("More images to load");
            addMultipleImages(restOfFiles, loaded);
          } else {
            console.log("Images all loaded, ready to process");
            updateImage(null, loaded);
          }
        },
        false
      );
      reader.readAsDataURL(file);

      // for each selected photo,
      // find an open slot and fill it
    }
  };

  const updateImage = (image, multipleImages = []) => {
    if (multipleImages.length == 0) {
      multipleImages.push(image);
    }
    console.log(multipleImages);
    const updatedImages = images.map((item) => item);
    console.log(updatedImages);
    multipleImages.map((item) => {
      if (updatedImages[item.position]) {
        // This is an existing image. Replace it.
        console.log(
          `updating existing image at position: ${item.position}. New item:`
        );
        // console.log(images[image.position]);
        console.log(item);
        console.log(`Existing item:`);
        console.log(updatedImages[item.position]);
        // if (!updatedImages[item.position].url && item.url) {
        if (item.url && !item.uuid) {
          // console.log("this is a new image");
          item.hasChanged = true;
        }
        // PostFormImage takes care of changes to existing image
        // }
        for (let i = 0; i < updatedImages.length; i++) {
          if (updatedImages[i].position == item.position) {
            updatedImages[i] = item;
            break;
          }
        }
      } else {
        const emptyImage = updatedImages.find(
          (img) => !img.url || img.url == ""
        );
        let position = updatedImages.length;
        if (emptyImage) {
          console.log(`found empty image at position: ${emptyImage.position}`);
          position = emptyImage.position;
        } else {
          console.log(`gave image a new position: ${position}`);
        }
        item.position = position;
        console.log(item);
        if (position < MAX_IMAGES) {
          updatedImages[position] = item;
        }
      }
    });
    // Remove all empty
    const cleanedImages = updatedImages.filter(
      (item) => item.url && item.url != ""
    );
    cleanedImages.map((item, i) => {
      item.position = i;
    });
    const hasEmptyImage = cleanedImages.find(
      (img) => !img.url || img.url == ""
    );
    if (!hasEmptyImage && cleanedImages.length < MAX_IMAGES) {
      const blank = {
        url: "",
        initialUrl: "",
        position: cleanedImages.length,
        hasChanged: false,
      };
      cleanedImages.push(blank);
    }
    console.log(cleanedImages);
    setImages(cleanedImages);
    if (cleanedImages.length > 1) {
      setEnableVideo(false);
    } else {
      setEnableVideo(true);
    }
  };

  if (redirectTo && redirectTo != "") {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div>
      <Navbar
        previousUrl={"/home"}
        title={postToEdit ? "Edit Post" : "New Post"}
      />

      <div className="container mb-5">
        {errorMessage && (
          <Alert
            message={errorMessage}
            onClose={() => setErrorMessage(null)}
            type="danger"
          />
        )}
        {user && token && !loading && (
          <div className="bg-light p-3 rounded my-4">
            <TagManager
              token={token}
              groups={groups}
              setGroups={setGroups}
              users={users}
              setUsers={setUsers}
              onSelect={(type, obj) => {
                // Remove Everyone group if it is still there, and user did not select Everyone
                let everyoneIsBeingUsed = false;
                console.log(groups);
                if (groups.length > 0) {
                  const found = groups.find(
                    (group) => group.name == "Everyone"
                  );
                  everyoneIsBeingUsed = found;
                }
                console.log(`everyoneIsBeingUsed = ${everyoneIsBeingUsed}`);
                if (everyoneIsBeingUsed) {
                  let remove = true;
                  if (type == "group" && obj.name == "Everyone") {
                    remvoe = false;
                  }
                  if (remove) {
                    setGroups((prevItems) =>
                      prevItems.filter((data) => data.name !== "Everyone")
                    );
                  }
                }
              }}
            />

            {!videoUrl && (
              <div className="mb-4">
                <label className="form-label text-medium-dark">Images</label>
                <div className="d-flex flex-wrap gap-3">
                  {images &&
                    images.length > 0 &&
                    images.map((image, i) => (
                      <PostFormImage
                        key={`post-image-${i}`}
                        config={config1}
                        initialUrl={image.initialUrl}
                        imagePosition={i}
                        uuid={image.uuid}
                        updateImage={updateImage}
                        addMultipleImages={addMultipleImages}
                      />
                    ))}
                </div>
              </div>
            )}
            {enableVideo && (
              <div className="mb-4">
                <label className="form-label text-medium-dark">Video</label>
                {videoErrorMessage && (
                  <Alert
                    message={videoErrorMessage}
                    onClose={() => setVideoErrorMessage(null)}
                    type="danger"
                  />
                )}
                {videoUrl ? (
                  <>
                    <ReactPlayer
                      url={videoUrl}
                      controls={true}
                      width="100%"
                      height="auto"
                    />
                    <button
                      className="btn"
                      onClick={() => {
                        setVideoData(null);
                        setVideoExtension(null);
                        setVideoUrl("");
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </>
                ) : (
                  <div
                    className="bg-white d-flex justify-content-center align-items-center"
                    style={config1}
                  >
                    <label htmlFor="video-input">
                      <i className="fa fa-video form-icon"></i>
                    </label>

                    <input
                      className="d-none"
                      type="file"
                      id="video-input"
                      name="video"
                      accept="video/mp4,video/x-m4v,video/*"
                      onChange={(e) => {
                        setVideoErrorMessage(null);
                        const file = e.target.files[0];
                        console.log(`size: ${file.size}`);
                        onVideoSelect(file);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {!postToEdit && (
              <div className="mb-4">
                <label className="d-block form-label text-medium-dark">
                  Poll
                </label>
                {!poll && (
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      setPoll({
                        message: "",
                        poll_choices: [
                          { id: 1, text: "" },
                          { id: 2, text: "" },
                        ],
                      })
                    }
                  >
                    Add Poll
                  </button>
                )}
                {poll && (
                  <div>
                    <label className="d-block form-label text-medium-dark small">
                      Poll cannot be edited after the post is created
                    </label>
                    <textarea
                      value={poll.message}
                      onChange={(e) => {
                        setPoll({
                          message: e.target.value,
                          poll_choices: poll.poll_choices,
                        });
                      }}
                      type="text"
                      className="form-control mb-1"
                      id="pollQuestion"
                      aria-describedby="poll"
                      placeholder="Poll question"
                    />
                    {poll.poll_choices.map((choice, index) => (
                      <div key={`choice-${choice.id}`} className="d-flex mb-1">
                        <input
                          value={choice.text}
                          onChange={(e) => {
                            const choices = poll.poll_choices.map((c) =>
                              c.id == choice.id
                                ? { id: c.id, text: e.target.value }
                                : c
                            );
                            setPoll({
                              message: poll.message,
                              poll_choices: choices,
                            });
                          }}
                          type="text"
                          className="form-control"
                          id="pollQuestion"
                          aria-describedby="poll"
                          placeholder="Choice"
                        />
                        {index > 1 && (
                          <button
                            className="btn"
                            style={{ width: "30px", height: "30px" }}
                            onClick={() => {
                              const choices = poll.poll_choices.filter(
                                (c) => c.id != choice.id
                              );
                              setPoll({
                                message: poll.message,
                                poll_choices: choices,
                              });
                            }}
                          >
                            <i
                              className="fa fa-trash"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </button>
                        )}
                      </div>
                    ))}
                    {poll.poll_choices.length < 10 && (
                      <div className="d-flex gap-2 mt-2 text-medium-dark">
                        <span>Add Choice</span>
                        <button
                          className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
                          style={{ width: "30px", height: "30px" }}
                          onClick={() => {
                            setPoll({
                              message: poll.message,
                              poll_choices: [
                                ...poll.poll_choices,
                                { id: poll.poll_choices.length + 1, text: "" },
                              ],
                            });
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <Form
              handleSubmit={handleSubmit}
              successMessage={successMessage}
              errorMessage={errorMessage}
              submitLabel={submitLabel}
              submitDisabled={submitDisabled}
            >
              <div className="mb-4">
                <label className="form-label text-medium-dark">Message</label>
                <textarea
                  style={{ minHeight: "200px" }}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="message"
                  placeholder="Must have a message"
                />
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}
export default PostForm;
