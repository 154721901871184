import React, { useEffect, useState } from "react";
import useAuthorization from "../hooks/useAuthorization";
import Navbar from "./Navbar";
import Alert from "./Alert";
import Email from "./user/Email";
import Password from "./user/Password";
import { Link } from "react-router-dom";

function Settings() {
  const user = useAuthorization();
  const [token, setToken] = useState(null);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  return (
    <div className="bg-light">
      <Navbar previousUrl={"/home"} title="Settings" />
      {successMessage && (
        <Alert
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
          type="success"
        />
      )}
      {errorMessage && (
        <Alert
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          type="danger"
        />
      )}
      <div
        className="row d-flex justify-content-center vh-100 bg-light px-2"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        {user && token && (
          <div className="col-12 col-md-10 col-lg-7 mt-2">
            <Email token={token} user={user} />

            <div className="bg-medium-light p-3 rounded my-4">
              <Password token={token} user={user} />
            </div>

            <div className="d-flex justify-content-center">
              <Link className="btn btn-outline-primary" to="/logout">
                Logout
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Settings;
